const routes = {
  path: '/mechanismCare',
  name: 'MechanismCare',
  meta: {
    title: '机构养老',
    keepAlive: false
  },
  redirect: {
    name: 'MechanismCare'
  },
  component: () => import('@/views/Layout/Main.vue'),
  children: [
    {
      path: 'mechanismCare/personsManage',
      name: 'PersonsManage',
      component: () => import('@/views/Pages/mechanismCare/mechaismManage/list'),
      meta: {
        title: '机构管理',
        keepAlive: false
      }
    },
    {
      path: 'mechanismCare/addManage/:type/:id?',
      name: 'AddManage',
      component: () => import('@/views/Pages/mechanismCare/mechaismManage/addOrEdit'),
      meta: {
        title: '机构管理',
        keepAlive: false
      }
    },
    {
      path: 'mechanismCare/addAged/:type/:id?',
      name: 'AddAged',
      component: () => import('@/views/Pages/mechanismCare/mechaismManage/addOrEditAged'),
      meta: {
        title: '机构管理',
        keepAlive: false
      }
    },
    {
      path: 'mechanismCare/agedDetail/:type/:id?',
      name: 'AgedDetail',
      component: () => import('@/views/Pages/mechanismCare/mechaismManage/detail'),
      meta: {
        title: '机构管理',
        keepAlive: false
      }
    },
    {
      path: 'mechanismCare/addStaff/:type/:id?',
      name: 'AddStaff',
      component: () => import('@/views/Pages/mechanismCare/mechaismManage/addOrEditStaff'),
      meta: {
        title: '机构管理',
        keepAlive: false
      }
    },
    {
      path: 'mechanismCare/DataCenter',
      name: 'MechanismCareDataCenter',
      component: () => import('@/views/Pages/mechanismCare/dataCenter'),
      meta: {
        title: '数据中心',
        keepAlive: false
      }
    },
    {
      path: 'mechanismCare/PersonnelInfo',
      name: 'PersonnelInfo',
      component: () => import('@/views/Pages/mechanismCare/personnelInfo/list'),
      meta: {
        title: '人员信息',
        keepAlive: false
      }
    },
    {
      path: 'mechanismCare/bed',
      name: 'BedInfo',
      component: () => import('@/views/Pages/mechanismCare/bed/list'),
      meta: {
        title: '床位管理',
        keepAlive: false
      }
    },
    {
      path: 'mechanismCare/bed/:id?',
      name: 'AddBedInfo',
      component: () => import('@/views/Pages/mechanismCare/bed/addOrEdit'),
      meta: {
        title: '床位管理',
        keepAlive: false
      }
    },
    {
      path: 'mechanismCare/entry',
      name: 'EntryInfo',
      component: () => import('@/views/Pages/mechanismCare/entry/list'),
      meta: {
        title: '办理入住',
        keepAlive: false
      }
    }

  ]
}

export default routes
