const routes = {
  path: '/homeCareBeds',
  name: 'HomeCareBeds',
  meta: {
    title: '家庭养老床位',
    keepAlive: false
  },
  redirect: {
    name: 'HomeCareBeds'
  },
  component: () => import('@/views/Layout/Main.vue'),
  children: [
    {
      path: 'homeCareBeds/bedCompany',
      name: 'BedCompany',
      component: () => import('@/views/Pages/homeCareBeds/bedCompany'),
      meta: {
        title: '施工单位',
        keepAlive: false
      }
    },
    {
      path: 'homeCareBeds/addBedCompany/:type/:id?',
      name: 'AddBedCompany',
      component: () => import('@/views/Pages/homeCareBeds/bedCompany/addOrEdit'),
      meta: {
        title: '施工单位',
        keepAlive: false
      }
    },
    {
      path: 'homeCareBeds/bedPerson',
      name: 'BedPerson',
      component: () => import('@/views/Pages/homeCareBeds/bedPerson'),
      meta: {
        title: '人员信息',
        keepAlive: false
      }
    },
    {
      path: 'homeCareBeds/addBedPerson/:type/:id?',
      name: 'AddBedPerson',
      component: () => import('@/views/Pages/homeCareBeds/bedPerson/addOrEdit'),
      meta: {
        title: '人员信息',
        keepAlive: false
      }
    }
  ]
}

export default routes
