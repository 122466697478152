import API from '@/store/API/api'
import Axios from '@/store/API/axios'

export default {
  // 志愿者列表
  volunteerPageList({ state }, params) {
    return Axios.post(`${API.volunteerPageList}`, params)
  },

  // 志愿者小组列表
  volunteerTeamPublicPageList({ state }, params) {
    return Axios.post(`${API.volunteerTeamPublicPageList}`, params)
  },

    // 志愿者小组成立后审核方法
    gotoDecisionMakeMethod({ state }, params) {
      return Axios.post(`${API.gotoDecisionMakeMethod}`, params)
    },
    // 志愿者小组队长更换
    volunteerTeamerChange({ state }, params) {
      return Axios.post(`${API.volunteerTeamerChange}`, params)
    },  
    


  // 团队成员列表
  volunteerTeamPublicPageMembersList({ state }, params) {
    return Axios.post(`${API.volunteerTeamPublicPageMembersList}`, params)
  }
}
