import Vue from 'vue'
import VueRouter from 'vue-router'
import { showNotification } from '@/utils/index'
import { MessageBox } from 'element-ui'

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 获取原型对象上的replace函数
const originalReplace = VueRouter.prototype.replace
// 修改原型对象中的replace方法
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

import HomeCare from './Modules/HomeCare' // 居家养老
import InstitutionalCension from './Modules/InstitutionalCension' // 机构养老
import agedEat from './Modules/agedEat' // 老年餐
import agedCounselor from './Modules/agedCounselor' // 养老顾问
import agedMarket from './Modules/agedMarket' // 养老早市
import nursingHome from './Modules/nursingHome' // 社区养老
import adaptationToAging from './Modules/adaptationToAging' // 适老化改造
import homeCareBeds from './Modules/homeCareBeds' // 家庭养老床位
import assessManage from './Modules/assessManage' // 志愿者服务
import smartElderlyPlat from './Modules/smartElderlyPlat' // 智慧养老床位管理平台
import task from './Modules/task' // 任务
import news from './Modules/news' // 新闻
import goods from './Modules/goods' // 积分商城
import neighborhood from './Modules/neighborhood' // 邻里圈
import feedback from './Modules/feedback' // 服务直通车
import party from './Modules/party' // 党群
import config from './Modules/config' // 配置
import audit from './Modules/audit' // 审核
import volunteer from './Modules/volunteer' // 志愿者
import device from './Modules/device' // 志愿者

import System from './Modules/System' // 系统管理

const routes = [
  {
    path: '/',
    redirect: {
      name: 'Login'
    }
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      isNotNeedLogin: true
    },
    component: () => import('@/views/Pages/Login/login.vue')
  },
  {
    path: '/homePage',
    name: 'HomePage',
    component: () => import('@/views/Pages/myHome/index.vue')
  },
  {
    path: '/layout',
    name: 'Layout',
    redirect: {
      name: 'HomePage'
    },
    component: () => import('@/views/Layout/Layout.vue'),
    children: [
      // TaskManage,
      HomeCare,
      InstitutionalCension,
      System,
      agedEat,
      agedCounselor,
      agedMarket,
      nursingHome,
      adaptationToAging,
      homeCareBeds,
      volunteer,
      assessManage,
      smartElderlyPlat,
      task,
      news,
      goods,
      neighborhood,
      feedback,
      party,
      config,
      audit,
      device
    ]
  },
  {
    path: '/404',
    name: '404',
    meta: {
      title: '404',
      isNotNeedLogin: true
    },
    component: () => import('@/views/Pages/404/404.vue')
  }
]

const router = new VueRouter({
  routes
})

// 全局路由前置守卫
router.beforeEach((to, from, next) => {
  const token = sessionStorage.token

  // 需要登录 && 未登录
  if (!to.meta.isNotNeedLogin && !token) {
    showNotification('error', '请先完成登录')
    next({ name: 'Login' })
    return
  }

  // TODO 放行所有权限
  // next()
  // return

  // 非权限路由 || 路由为空
  if (!to.meta.isAuthRoute || !to.name) {
    next()
    return
  }

  const roles = sessionStorage.roles

  // 拥有权限
  if (roles && to.name in JSON.parse(roles)) {
    next()
    return
  }

  // 无权限
  MessageBox.confirm('禁止访问', '权限不足', {
    confirmButtonText: '关闭',
    showCancelButton: false,
    type: 'warning'
  }).then(() => {}).catch(() => {})
})

export default router
