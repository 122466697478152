import API from '@/store/API/api'
import Axios from '@/store/API/axios'

const System = {

  

  // 获取居家养老-人员管理-人员管理列表
  getDictTypeListServe({ state }, params) {
    return Axios.post(`${API.getDictTypeList}`, params)
  },
  // 添加字典类型
  addDictTypeServe({ state }, params) {
    return Axios.post(`${API.addDictType}`, params)
  },
  // 删除字典类型
  deleteDictTypesServe({ state }, params) {
    console.log('xxxxxxxxxxxxxx')
    console.log(params)
    console.log('xxxxxxxxxxxxxx')
    return Axios.post(`${API.deleteDictType}/${params.id}`, params)
  },
  // 数据字典-列表
  getDictListServe({ state }, params) {
    return Axios.post(`${API.getDictList}`, params)
  },
  // 添加数据字典
  addDictServe({ state }, params) {
    return Axios.post(`${API.addDict}`, params)
  },
  // 删除数据字典
  delDictServe({ state }, params) {
    return Axios.get(`${API.deleteDict}/${params.id}`)
  },

  getCommunityInfo({ state }, params) {
    return Axios.post(`${API.getCommunityInfo}`, params)
  },

  delCommunityInfo({ state }, params) {
    return Axios.get(`${API.delCommunityInfo}`, {
      params
    })
  },

  saveCommunityInfo({ state }, params) {
    return Axios.post(`${API.saveCommunityInfo}`, params)
  },

  // 获取角色列表
  getRoleListServe({ state }, params) {
    return Axios.get(`${API.roleList}`)
  },
  // 获取角色
  getSubSysListServe({ state }, params) {
    return Axios.get(`${API.getSubSysList}`)
  },
  // 添加数据字典
  getMenuListServe({ state }, params) {
    return Axios.post(`${API.menuList}`, params)
  },
  // 获取系统用户列表
  getUserPageList({ state }, params) {
    return Axios.post(`${API.getUserPageList}`, params)
  },

  // 获取角色列表
  getRoleList({ state }, params) {
    return Axios.get(`${API.getRoleList}`)
  },

  // 增加系统用户
  addSystemUser({ state }, params) {
    return Axios.post(`${API.addSystemUser}`, params)
  },

  // 启用禁用系统用户
  changeUserStatus({ state }, params) {
    return Axios.post(`${API.changeUserStatus}`, params)
  },

  // 增加用户角色
  addUserRole({ state }, params) {
    return Axios.post(`${API.addUserRole}`, params)
  },

  // 系统用户修改密码
  userChangePwd({ state }, params) {
    return Axios.post(`${API.userChangePwd}`, params)
  },
  // 系统我的密码
  userChangeMyPwd({ state }, params) {
    return Axios.post(`${API.userChangeMyPwd}`, params)
  },

  // 删除系统用户
  deleteRole({ state }, params) {
    return Axios.get(`${API.deleteRole}/${params.id}`)
  },

  // 删除系统用户
  editUserInfo({ state }, params) {
    return Axios.post(`${API.editUerInfo}`, params)
  },

  getWorkPersonListServe2({ state }, params) {
    return Axios.post(`${API.getWorkPersonListServe2}`, params)
  },
  addSystemWorkPersonUser({ state }, params) {
    return Axios.post(`${API.addSystemWorkPersonUser}`, params)
  },
  addSystemAppUser({ state }, params) {
    return Axios.post(`${API.addSystemAppUser}`, params)
  },

  // 访问日志
  getLogListServe({ state }, params) {
    return Axios.post(`${API.logList}`, params)
  },
  // 上传文件查询
  getUploadFileListServe({ state }, params) {
    return Axios.post(`${API.uploadFileList}`, params)
  },
  // 抬头管理
  getGlobalEvn({ state }, params) {
    return Axios.post(`${API.globalEvn}`, params)
  },
  saveGlobalEvn({ state }, params) {
    return Axios.post(`${API.saveGlobalEvn}`, params)
  },
  // 获取首页展示菜单
  getSystemList({ state }, params) {
    return Axios.get(`${API.getSystemList}`)
  },
  // 用户类型
  getUserTypesServe({ state }, params) {
    return Axios.get(`${API.getUserTypesServe}/${params.type}`)
  },

  // 增加区域
  addRegionServe({ state }, params) {
    return Axios.post(`${API.addRegionServe}`, params)
  },
  editRegionServe({ state }, params) {
    return Axios.post(`${API.editRegionServe}`, params)
  },
  delRegionServe({ state }, params) {
    return Axios.post(`${API.delRegionServe}/${params}`)
  },
  // 获取默认头像
  getPhoto({ state }, params) {
    return Axios.get(`${API.getPhoto}`)
  },

  // 权限列表
  authList({ state }, params) {
    return Axios.get(`${API.authList}`, params)
  },
  // 添加权限
  authAdd({ state }, params) {
    return Axios.post(`${API.authAdd}`, params)
  },
  // 修改权限
  authUpdate({ state }, params) {
    return Axios.post(`${API.authUpdate}`, params)
  },
  // 删除权限
  authDelete({ state }, params) {
    return Axios.post(`${API.authDelete}/${params.id}`, params)
  },
  // 获取用户权限
  authGetUserAuth({ state }, params) {
    return Axios.post(`${API.authGetUserAuth}`, params)
  },

  // 角色列表
  roleList({ state }, params) {
    return Axios.get(`${API.roleList}`, params)
  },
  // 添加角色
  roleAdd({ state }, params) {
    return Axios.post(`${API.roleAdd}`, params)
  },
  // 修改角色
  roleUpdate({ state }, params) {
    return Axios.post(`${API.roleUpdate}`, params)
  },
  // 删除角色
  roleDelete({ state }, params) {
    return Axios.post(`${API.roleDelete}/${params.id}`, params)
  },
  // 获取权限
  roleGetAuth({ state }, params) {
    return Axios.post(`${API.roleGetAuth}/${params.id}`, params)
  },
  // 设置权限
  roleSetAuth({ state }, params) {
    return Axios.post(`${API.roleSetAuth}`, params)
  }
}

export default System
