import API from '@/store/API/api'
import Axios from '@/store/API/axios'

export default {
  // 邻里圈列表
  neighborhoodPublicPageList({ state }, params) {
    return Axios.post(`${API.neighborhoodPublicPageList}`, params)
  },
  // 新增邻里圈
  neighborhoodAdd({ state }, params) {
    return Axios.post(`${API.neighborhoodAdd}`, params)
  },
  // 邻里圈详情
  neighborhoodPublicDetail({ state }, params) {
    return Axios.post(`${API.neighborhoodPublicDetail}/${params.id}`, params)
  },
  // 修改邻里圈
  neighborhoodUpdate({ state }, params) {
    return Axios.post(`${API.neighborhoodUpdate}`, params)
  },

  // 邻里圈话题列表
  neighborhoodTopicsPublicPageList({ state }, params) {
    return Axios.post(`${API.neighborhoodTopicsPublicPageList}`, params)
  },
  // 新增邻里圈话题
  neighborhoodTopicsAdd({ state }, params) {
    return Axios.post(`${API.neighborhoodTopicsAdd}`, params)
  },
  // 修改邻里圈话题
  neighborhoodTopicsUpdate({ state }, params) {
    return Axios.post(`${API.neighborhoodTopicsUpdate}`, params)
  },
  // 删除邻里圈话题
  neighborhoodTopicsDelete({ state }, params) {
    return Axios.post(`${API.neighborhoodTopicsDelete}/${params.id}`, params)
  }
}
