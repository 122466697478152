import Vue from 'vue'
import Vuex from 'vuex'
import API from '@/store/API/api'
import createPersistedState from 'vuex-persistedstate'
import Router from '@/router'
import Axios from '@/store/API/axios'
import TaskManage from './Modules/TaskManage.js'
import HomeCare from './Modules/HomeCare.js'
import mechanismCare from './Modules/mechanismCare.js'
import nursingHome from './Modules/nursingHome.js'
import adaptationToAging from './Modules/adaptationToAging.js'
import homeCareBeds from './Modules/homeCareBeds.js'
import assessManage from './Modules/assessManage.js'
import smartElderlyPlat from './Modules/smartElderlyPlat'
import task from './Modules/task'
import news from './Modules/news'
import goods from './Modules/goods'
import neighborhood from './Modules/neighborhood'
import volunteer from './Modules/volunteer'
import feedback from './Modules/feedback'
import party from './Modules/party'
import config from './Modules/config'
import audit from './Modules/audit'
import device from './Modules/device'
import file from './Modules/file'

import System from './Modules/System.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isCollapse: true,
    toName: '',
    currTreeNode: null,
    tabs: [],
    tabName: null,
    activeMenu: null,
    userInfos: null,
    seqId: '',
    plateNo: ''
  },

  mutations: {
    // 全局处理路由跳转
    goToPage(state, item) {
      // console.log(item, 'item')
      if (item.path === 'smartCaringPlatform') {
        window.open('http://qiai.canmore.net.cn/')
        return
      }
      state.tabName = item.name
      state.activeMenu = item
      const tabs = localStorage.tabs ? JSON.parse(localStorage.tabs) : []
      let flag = false
      if (tabs.length) {
        for (const tab of tabs) {
          // eslint-disable-next-line eqeqeq
          if (tab.name == item.name) {
            flag = true
            break
          }
        }
      } else {
        state.tabs = []
      }
      // eslint-disable-next-line eqeqeq
      if (!flag && item.name != 'Dashboard') {
        state.tabs.push(item)
        localStorage.tabs = JSON.stringify(state.tabs)
      }

      Router.push({
        name: item.name
      })
    },

    // 删除页面上的选项卡
    removeTab(state, tabName) {
      const tabs = localStorage.tabs ? JSON.parse(localStorage.tabs) : []
      tabs.forEach((tab, index) => {
        if (tab.name === tabName) {
          const nextTab = tabs[index + 1] || tabs[index - 1]
          if (nextTab) {
            state.tabName = nextTab.name
            state.activeMenu = nextTab
            tabs.splice(index, 1)
            state.tabs.splice(index, 1)
            localStorage.tabs = JSON.stringify(tabs)
            Router.replace({
              name: nextTab.name
            })
          }
        }
      })
    },

    goBack() {
      Router.back(-1)
    },

    // 储存登录用户信息
    mUserInfo(state, data) {
      
      state.userInfos = data
      sessionStorage.userInfo = JSON.stringify(data)
    },
    mSeqId(state, data) {
      state.seqId = data
      sessionStorage.seqId = data
    },
    mPlateNo(state, data) {
      state.plateNo = data
      sessionStorage.plateNo = data
    }
  },
  getters: {
    userInfos(state) {
      const userInfos = state.userInfos ? state.userInfos : JSON.parse(sessionStorage.userInfo)
      return userInfos
    },
    seqId(state) {
      const seqId = state.seqId ? state.seqId : sessionStorage.seqId
      return seqId
    },
    plateNo(state) {
      const plateNo = state.plateNo ? state.plateNo : sessionStorage.plateNo
      return plateNo
    }
  },
  actions: {
    // 登陆
    loginServe({ state }, { params }) {
      return Axios.post(`${API.login}`, params)
    },
    // 退出登陆
    logOutServe({ state }) {
      return Axios.get(`${API.logOut}`)
    },
    // 退出系统
    logoutByClassbao({ state }) {
      return Axios.get(`${API.logoutByClassbao}`)
    },

    // 获取用户信息
    userInfo({ state }, { params }) {
      return Axios.post(`${API.getUserInfo}/`, params)
    },

    // 用户登录信息
    loginUserInfo({ state }) {
      return Axios.get(`${API.loginUserInfo}`)
    },

    // 下载图片地址(base64)
    downloadImg({ state }, params) {
      return Axios.post(`${API.downloadImg}/${params.fileId}`, params)
    },
    // 获取数据字典
    getSysDictServe({ state }, params) {
      return Axios.get(`${API.getSysDict}/${params.typeCode}`)
    },
    // 获取附件详情
    getFileDetailServe({ state }, params) {
      return Axios.get(`${API.fileDetail}/${params.fileId}`)
    },
    // 获取区县、街道列表
    getArealistServe({ state }, params) {
      return Axios.get(`${API.getArealist}`, {
        params
      })
    },
    getstrealistServe({ state }, params) {
      return Axios.get(`${API.getstrealist}`, {
        params
      })
    },

    // 获取省、市、区县、街道、社区
    getProvincesServe({ state }, params) {
      return Axios.get(`${API.getProvinces}`, {
        params
      })
    },
    getCitysServe({ state }, params) {
      return Axios.get(`${API.getCitys}`, {
        params
      })
    },
    getAreasServe({ state }, params) {
      return Axios.get(`${API.getAreas}`, {
        params
      })
    },
    getStreetsServe({ state }, params) {
      return Axios.get(`${API.getStreets}`, {
        params
      })
    },
    getCommunitysServe({ state }, params) {
      return Axios.get(`${API.getCommunitys}`, {
        params
      })
    },
    getRegionDetialServe({ state }, params) {
      return Axios.get(`${API.getReGionDetail}`, {
        params
      })
    },
    getRegionServe({ state }, params) {
      return Axios.get(`${API.getReGionByPid}`, {
        params
      })
    },
    ...TaskManage,
    ...HomeCare,
    ...mechanismCare,
    ...System,
    ...nursingHome,
    ...adaptationToAging,
    ...homeCareBeds,
    ...assessManage,
    ...smartElderlyPlat,
    ...task,
    ...news,
    ...goods,
    ...neighborhood,
    ...volunteer,
    ...feedback,
    ...party,
    ...config,
    ...audit,
    ...device,
    ...file
  },
  modules: {},
  plugins: [createPersistedState({
    storage: window.localStorage,
    reducer(val) {
      return {
        // 需要保持数据持久化的内容，只储存state中的部分数据
        tabs: val.tabs,
        tabName: val.tabName,
        activeMenu: val.activeMenu
      }
    }
  })]
})
