const routes = {
  path: '/smartElderlyPlat',
  name: 'SmartElderlyPlat',
  meta: {
    title: '智慧养老床位管理平台',
    keepAlive: false
  },
  redirect: {
    name: 'SmartElderlyPlat'
  },
  component: () => import('@/views/Layout/Main.vue'),
  children: [
    // 志愿者档案
    {
      path: 'dataFlash',
      name: 'DataFlash',
      component: () => import('@/views/Pages/smartElderlyPlat/dataFlash'),
      meta: {
        title: '数据快报',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'smartElderly',
      name: 'SmartElderly',
      component: () => import('@/views/Pages/smartElderlyPlat/smartElderly'),
      meta: {
        title: '智慧养老床位',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'weekReport',
      name: 'WeekReport',
      component: () => import('@/views/Pages/smartElderlyPlat/weekReport'),
      meta: {
        title: '周报',
        keepAlive: false
      }
    }
  ]
}

export default routes
