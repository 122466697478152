import API from '@/store/API/api'
import Axios from '@/store/API/axios'

export default {
  // 任务列表
  pubTaskPublicPageList({ state }, params) {
    return Axios.post(`${API.pubTaskPublicPageList}`, params)
  },
  // 任务认领列表
  pubTaskPublicPageClaimList({ state }, params) {
    return Axios.post(`${API.pubTaskPublicPageClaimList}`, params)
  },
  // 新增任务
  pubTaskAdd({ state }, params) {
    return Axios.post(`${API.pubTaskAdd}`, params)
  },
  // 任务详情
  pubTaskPublicDetail({ state }, params) {
    return Axios.get(`${API.pubTaskPublicDetail}/${params.id}`, params)
  },
  // 修改任务
  pubTaskUpdate({ state }, params) {
    return Axios.post(`${API.pubTaskUpdate}`, params)
  }
}
