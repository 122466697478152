const routes = {
  path: '/volunteer',
  name: 'Volunteer',
  meta: {
    title: '志愿者管理',
    keepAlive: false
  },
  redirect: {
    name: 'Volunteer'
  },
  component: () => import('@/views/Layout/Main.vue'),
  children: [
    {
      path: 'volunteer/volunteer',
      name: 'VolunteerVolunteer',
      component: () => import('@/views/Pages/volunteer/volunteer'),
      meta: {
        title: '志愿者',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'volunteer/team',
      name: 'VolunteerTeam',
      component: () => import('@/views/Pages/volunteer/team'),
      meta: {
        title: '志愿者小组',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'volunteer/teamVolunteer/:teamId',
      name: 'VolunteerTeamVolunteer',
      component: () => import('@/views/Pages/volunteer/team/components/volunteer'),
      meta: {
        title: '小组成员',
        keepAlive: false,
        isAuthRoute: true
      }
    }
  ]
}

export default routes
