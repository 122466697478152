import API from '@/store/API/api'
import Axios from '@/store/API/axios'

export default {
  // 意见反馈列表
  feedbackPageList({ state }, params) {
    return Axios.post(`${API.feedbackPageList}`, params)
  },
  // 意见反馈详情
  feedbackUpdate({ state }, params) {
    return Axios.post(`${API.feedbackUpdate}`, params)
  },
  // 删除意见反馈
  feedbackDelete({ state }, params) {
    return Axios.post(`${API.feedbackDelete}/${params.id}`, params)
  }
}
