<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import generateSQLFunc from './utils/menuNode'

export default {
  name: 'App',
  created() {
    // TODO 获取所有权限节点SQL
    // generateSQLFunc()
  }
}
</script>

<style>
#app {
  min-width: 1200px;
  width: 100%;
  height: 100%;
  position: relative;
}
.el-date-editor.el-input {
  /* width: 220px; */
  /* width: 100% !important; */
}
/* 对element的一些修改 */
.el-submenu__title,
.el-breadcrumb,
.el-tabs__item {
  font-size: 17px !important;
}
.el-submenu .el-menu-item {
  font-size: 16px;
}
</style>
