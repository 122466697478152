import axios from 'axios'
import Router from '@/router'
import { MessageBox, Message } from 'element-ui'
// import store from "@/store";
const Axios = axios.create({
  baseURL: process.env.NODE_ENV == 'development' ? '/api' : process.env.VUE_APP_BASEURL,
  timeout: 0,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8' // 设置跨域头部
  }
})
console.log(process.env)
Axios.defaults.withCredentials = true
// 添加请求拦截器
Axios.interceptors.request.use(
  function(config) {
    // 在发送请求之前做些什么
    config.data = JSON.stringify(config.data)
    const token = sessionStorage.token
    if (token) {
      config.headers.Authorization = token
    }
    return config
  },
  function(error) {
    console.log(error)
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)
Axios.interceptors.response.use(
  response => {
    const { data } = response
    const code = parseInt(data.code, 10)
    if (code === 400) {
      Message.error(data.msg)
      return Promise.reject(data)
    }
    if (code === 401) {
      Message.error(data.msg)
      sessionStorage.clear()
      location.reload()
      return Promise.reject(data)
    }
    // if(code != 200||code != '200'){
    //   Message.error(data.msg)
    //   return Promise.reject(data)
    // }
    return Promise.resolve(data)
  },
  err => {
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.message = '错误请求'
          break
        case 401:
          err.message = '未授权，请重新登录'
          sessionStorage.clear()
          location.reload()
          break
        case 403:
          err.message = '拒绝访问'
          break
        case 404:
          err.message = '请求错误,未找到该资源'
          break
        case 405:
          err.message = '请求方法未允许'
          break
        case 408:
          err.message = '请求超时'
          break
        case 500:
          err.message = '服务器端出错'
          break
        case 501:
          err.message = '网络未实现'
          break
        case 502:
          err.message = '网络错误'
          break
        case 503:
          err.message = '服务不可用'
          break
        case 504:
          err.message = '网络超时'
          break
        case 505:
          err.message = 'http版本不支持该请求'
          break
        default:
          err.message = `连接错误${err.response.status}`
      }
    }

    return Promise.reject(err)
  }
)

export default Axios
