import { checkPermission as utilsCheckPermission } from '@/utils'

function checkPermission(el, binding) {
  const { value } = binding
  utilsCheckPermission(value).then(() => {
  }).catch(() => {
    el.parentNode && el.parentNode.removeChild(el)
  })
}

export default {
  inserted(el, binding) {
    checkPermission(el, binding)
  },
  update(el, binding) {
    checkPermission(el, binding)
  }
}
