import API from '@/store/API/api'
import axios from 'axios'

export default {
  // 下载文件
  downloadFile({ state }, fileId) {
    return new Promise((resolve, reject) => {
      let url = process.env.NODE_ENV === 'development' ? '/api' : process.env.VUE_APP_BASEURL
      url += API.fileDetail + '/' + fileId

      axios({
        method: 'get',
        data: {},
        url,
        responseType: 'blob',
        headers: {
          Authorization: sessionStorage.token
        }
      }).then(res => {
        resolve(res)
      }).catch(e => {
        reject(e)
      })
    })
  }
}
