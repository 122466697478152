import API from '@/store/API/api'
import Axios from '@/store/API/axios'

const smartElderlyPlat = {

  // 数据快报
  getDataFlash({ state }, params) {
    return Axios.post(`${API.getDataFlash}`, params)
  },
  // 智慧养老床位列表
  getBedList({ state }, params) {
    return Axios.post(`${API.getBedList}`, params)
  },
  // 预警信息 - 列表
  getFlashList({ state }, params) {
    return Axios.post(`${API.getFlashList}`, params)
  },
  // 预警信息 - 一键处理
  clickProcessing({ state }, params) {
    return Axios.post(`${API.clickProcessing}`, params)
  },
  // 预警信息 - 列表
  getFlashDetail({ state }, params) {
    return Axios.post(`${API.getFlashDetail + '/' + params}`)
  },
  // 周报
  getWeekReport({ state }, params) {
    return Axios.post(`${API.getWeekReport}`, params)
  },
 // 下载老人周报数据
 exportExcelWeeks({ state }, params) {
  return Axios.post(`${API.exportExcelWeeks}`, params)
},


  // 语音处理
  textToVol({ state }, params) {
    return Axios.post(`${API.textToVol}`, params)
  },
  // 关爱平台智能设备
  caringPlatformDevice({ state }, params) {
    return Axios.post(`${API.caringPlatformDevice + '?alarmSource=' + params.alarmSource}`, params)
  },
  // 关爱平台老人列表
  caringPlatformOlderPersonList({ state }, params) {
    return Axios.post(`${API.caringPlatformOlderPersonList}`, params)
  },
  //  智能设备上报信息 - 活动传感器 (注：按天分页)
  caringPlatformHdInfo({ state }, params) {
    return Axios.post(`${API.caringPlatformHdInfo}`, params)
  },
  // 关爱平台老人列表
  caringPlatformSbInfo({ state }, params) {
    return Axios.post(`${API.caringPlatformSbInfo}`, params)
  },
  // 关爱平台老人列表
  caringPlatformSosInfo({ state }, params) {
    return Axios.post(`${API.caringPlatformSosInfo}`, params)
  },
  // 关爱平台老人列表
  caringPlatformYgInfo({ state }, params) {
    return Axios.post(`${API.caringPlatformYgInfo}`, params)
  }

}

export default smartElderlyPlat
