const routes = {
  path: '/goods',
  name: 'Goods',
  meta: {
    title: '积分商城管理',
    keepAlive: false
  },
  redirect: {
    name: 'Goods'
  },
  component: () => import('@/views/Layout/Main.vue'),
  children: [
    {
      path: 'goods/goods',
      name: 'GoodsGoods',
      component: () => import('@/views/Pages/goods/goods'),
      meta: {
        title: '商品管理',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'goods/exchangeRecord/:goodsId',
      name: 'GoodsExchangeRecord',
      component: () => import('@/views/Pages/goods/goods/components/exchange'),
      meta: {
        title: '商品兑换记录',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'goods/exchange',
      name: 'GoodsExchange',
      component: () => import('@/views/Pages/goods/exchange'),
      meta: {
        title: '兑换管理',
        keepAlive: false,
        isAuthRoute: true
      }
    }
  ]
}

export default routes
