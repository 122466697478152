import { Notification } from 'element-ui'
import { isString } from 'element-ui/src/utils/types'

/**
 * 通知弹窗
 * @param type
 * @param message
 */
export function showNotification(type, message) {
  let title
  switch (type) {
    case 'success':
      title = '成功'
      message = message || ''
      break
    case 'warning':
      title = '警告'
      message = message || ''
      break
    case 'error':
      title = '错误'
      message = message || ''
      break
    default:
      type = 'info'
      title = '提示'
      message = message || ''
      break
  }

  Notification({
    type,
    title,
    message,
    duration: 0
  })
}

/**
 * 权限验证
 * @param toNameData
 * @returns {Promise<unknown>}
 */
export function checkPermission(toNameData) {
  return new Promise((resolve, reject) => {
    // TODO 放行所有权限
    // resolve(!toNameData || isString(toNameData) ? toNameData : toNameData[0])
    // return

    let roles = sessionStorage.roles

    if (toNameData) {
      // 权限为空
      if (!roles) {
        reject(toNameData)
        return
      }

      roles = JSON.parse(roles)
      toNameData = isString(toNameData) ? [toNameData] : toNameData

      let run = true
      let index = 0
      while (run && toNameData[index]) {
        if (toNameData[index] in roles) {
          run = false
          resolve(toNameData[index])
          return
        }
        index++
      }

      reject()
    } else {
      resolve()
    }
  })
}
