import API from '@/store/API/api'
import Axios from '@/store/API/axios'

const AssessManage = {
  // 获取居家养老-数据统计-人员信息统计
  initElderData({ state }, params) {
    return Axios.get(`${API.initElderData}`)
  },
  // 获取居家养老-数据统计-企业信息统计
  initServiceCompanyData({ state }, params) {
    return Axios.get(`${API.initServiceCompanyData}`)
  },
  // 获取居家养老-人员管理-人员管理列表
  getHomePersonListServe({ state }, params) {
    return Axios.post(`${API.getHomePersonList}`, params)
  },

  // 人员评估列表
  assessList({ state }, params) {
    return Axios.post(`${API.assessList}`, params)
  },

  assessListDetail({ state }, params) {
    return Axios.get(`${API.assessListDetail}/${params.id}`)
  },
  // 人员新增
  addAssess({ state }, params) {
    return Axios.post(`${API.addAssess}`, params)
  },

  // 服务报告新增
  addreport({ state }, params) {
    return Axios.post(`${API.addreport}`, params)
  },
  // 服务报告列表
  reportList({ state }, params) {
    return Axios.post(`${API.reportList}`, params)
  },
  // 服务报告详情
  reportDetail({ state }, params) {
    return Axios.post(`${API.reportDetail}/${params}`, params)
  },
  // 服务报告删除
  deletereport({ state }, params) {
    return Axios.post(`${API.deletereport}/${params}`, params)
  },
  // 服务报告修改
  updatereport({ state }, params) {
    return Axios.post(`${API.updatereport}`, params)
  },
  // 项目类别列表
  getProjectList({ state }, params) {
    return Axios.post(`${API.getProjectList}`, params)
  },
  // 项目类别添加
  projectAdd({ state }, params) {
    return Axios.post(`${API.projectAdd}`, params)
  },
  // 项目类别修改
  projectUpdate({ state }, params) {
    return Axios.post(`${API.projectUpdate}`, params)
  },
  // 项目类别删除
  projectDelete({ state }, params) {
    return Axios.post(`${API.projectDelete}/${params}`, params)
  },
  // 项目类别详情
  projectDetail({ state }, params) {
    return Axios.post(`${API.projectDetail}/${params}`, params)
  },
  // 产品列表
  getProductList({ state }, params) {
    return Axios.post(`${API.getProductList}`, params)
  },
  // 产品添加
  productAdd({ state }, params) {
    return Axios.post(`${API.productAdd}`, params)
  },
  // 产品修改
  productUpdate({ state }, params) {
    return Axios.post(`${API.productUpdate}`, params)
  },
  // 产品删除
  productDelete({ state }, params) {
    return Axios.post(`${API.productDelete}/${params}`, params)
  },
  // 产品详情
  productDetail({ state }, params) {
    return Axios.post(`${API.productDetail}/${params}`, params)
  },

  // 审核列表
  elderlyAuditList({ state }, params) {
    return Axios.post(`${API.elderlyAuditList}`, params)
  },

  // 审核
  elderlyAuditAudit({ state }, params) {
    return Axios.post(`${API.elderlyAuditAudit}`, params)
  },

  // 一键审核
  elderlyAuditAuditAll({ state }, params) {
    return Axios.post(`${API.elderlyAuditAuditAll}`, params)
  },
  // 审核删除
  elderlyAuditDelete({ state }, params) {
    return Axios.post(`${API.elderlyAuditDelete}/${params}`, params)
  },
  // 审核详情
  elderlyAuditDetail({ state }, params) {
    return Axios.post(`${API.elderlyAuditDetail}/${params}`, params)
  }
}

export default AssessManage
