const routes = {
  path: '/assessManage',
  name: 'AssessManage',
  meta: {
    title: '评估管理',
    keepAlive: false
  },
  redirect: {
    name: 'AssessManage'
  },
  component: () => import('@/views/Layout/Main.vue'),
  children: [
    // 人员管理
    {
      path: 'personAssess',
      name: 'PersonAssess',
      component: () => import('@/views/Pages/assessManage/personAssess/index'),
      meta: {
        title: '人员评估',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'personAssess/detail',
      name: 'PersonAssessDetail',
      component: () => import('@/views/Pages/assessManage/personAssess/personAssessDetail'),
      meta: {
        title: '人员评估详情',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'personRecord',
      name: 'PersonRecord',
      component: () => import('@/views/Pages/assessManage/personRecord/index'),
      meta: {
        title: '养老报告',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'personRecord/detail',
      name: 'PersonRecordDetail',
      component: () => import('@/views/Pages/assessManage/personRecord/personRecordDetail'),
      meta: {
        title: '养老报告',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'oldChange',
      name: 'OldChange',
      component: () => import('@/views/Pages/assessManage/oldchange/index'),
      meta: {
        title: '适老化改造',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'oldChange/detail',
      name: 'OldChangeDetail',
      component: () => import('@/views/Pages/assessManage/oldchange/oldchangeDetail'),
      meta: {
        title: '适老化改造',
        keepAlive: false,
        isAuthRoute: true
      }
    }, {
      path: 'oldChangeProject',
      name: 'oldChangeProject',
      component: () => import('@/views/Pages/assessManage/project/index'),
      meta: {
        title: '适老化改造项目清单',
        keepAlive: false,
        isAuthRoute: true
      }
    }, {
      path: 'oldChangeProduct',
      name: 'oldChangeProduct',
      component: () => import('@/views/Pages/assessManage/product/index'),
      meta: {
        title: '适老化改造产品清单',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'examine/audit',
      name: 'oldChangeExamineAudit',
      component: () => import('@/views/Pages/assessManage/examine/audit'),
      meta: {
        title: '待审核',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'examine/audited',
      name: 'oldChangeExamineAudited',
      component: () => import('@/views/Pages/assessManage/examine/audited'),
      meta: {
        title: '待审核',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'examine/auditedDetail',
      name: 'oldChangeExamineAuditedDetail',
      component: () => import('@/views/Pages/assessManage/examine/auditDetail'),
      meta: {
        title: '审核记录',
        keepAlive: false,
        isAuthRoute: true
      }
    }

  ]
}

export default routes
