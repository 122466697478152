const routes = {
  path: '/party',
  name: 'Party',
  meta: {
    title: '党群管理',
    keepAlive: false
  },
  redirect: {
    name: 'Party'
  },
  component: () => import('@/views/Layout/Main.vue'),
  children: [
    {
      path: 'party/organization',
      name: 'PartyOrganization',
      component: () => import('@/views/Pages/party/organization'),
      meta: {
        title: '党组织',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'party/organizationParty/:organizationId',
      name: 'PartyOrganizationParty',
      component: () => import('@/views/Pages/party/organization/components/party'),
      meta: {
        title: '党组织成员',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'party/party',
      name: 'PartyParty',
      component: () => import('@/views/Pages/party/party'),
      meta: {
        title: '党员',
        keepAlive: false
      }
    }
  ]
}

export default routes
