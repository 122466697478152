import API from '@/store/API/api'
import Axios from '@/store/API/axios'

export default {
  // 商品列表
  goodsPublicPageList({ state }, params) {
    return Axios.post(`${API.goodsPublicPageList}`, params)
  },
  // 新增商品
  goodsAdd({ state }, params) {
    return Axios.post(`${API.goodsAdd}`, params)
  },
  // 商品详情
  goodsPublicDetail({ state }, params) {
    return Axios.get(`${API.goodsPublicDetail}/${params.id}`, params)
  },
  // 修改商品
  goodsUpdate({ state }, params) {
    return Axios.post(`${API.goodsUpdate}`, params)
  },

  // 兑换列表
  goodsExchangePageList({ state }, params) {
    return Axios.post(`${API.goodsExchangePageList}`, params)
  },
  // 核销
  goodsExchangeCheckout({ state }, params) {
    return Axios.post(`${API.goodsExchangeCheckout}/${params.code}`, params)
  }
}
