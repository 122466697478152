const routes = {
  path: '/news',
  name: 'News',
  meta: {
    title: '新闻管理',
    keepAlive: false
  },
  redirect: {
    name: 'News'
  },
  component: () => import('@/views/Layout/Main.vue'),
  children: [
    {
      path: 'news/news',
      name: 'NewsNews',
      component: () => import('@/views/Pages/news/news'),
      meta: {
        title: '新闻管理',
        keepAlive: false,
        isAuthRoute: true
      }
    }
  ]
}

export default routes
