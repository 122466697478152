const routes = {
  path: '/adaptationToAging',
  name: 'AdaptationToAging',
  meta: {
    title: '适老化改造',
    keepAlive: false
  },
  redirect: {
    name: 'AdaptationToAging'
  },
  component: () => import('@/views/Layout/Main.vue'),
  children: [
    {
      path: 'adaptationToAging/chgCompany',
      name: 'ChgCompany',
      component: () => import('@/views/Pages/adaptationToAging/chgCompany'),
      meta: {
        title: '施工单位',
        keepAlive: false
      }
    },
    {
      path: 'adaptationToAging/addChgCompany/:type/:id?',
      name: 'AddChgCompany',
      component: () => import('@/views/Pages/adaptationToAging/chgCompany/addOrEdit'),
      meta: {
        title: '施工单位',
        keepAlive: false
      }
    },
    {
      path: 'adaptationToAging/chgPerson',
      name: 'ChgPerson',
      component: () => import('@/views/Pages/adaptationToAging/chgPerson'),
      meta: {
        title: '人员信息',
        keepAlive: false
      }
    },
    {
      path: 'adaptationToAging/addChgPerson/:type/:id?',
      name: 'AddChgPerson',
      component: () => import('@/views/Pages/adaptationToAging/chgPerson/addOrEdit'),
      meta: {
        title: '人员信息',
        keepAlive: false
      }
    }
  ]
}

export default routes
