const routes = {
  path: '/homeCare',
  name: 'HomeCare',
  meta: {
    title: '居家养老',
    keepAlive: false
  },
  redirect: {
    name: 'PersonManage'
  },
  component: () => import('@/views/Layout/Main.vue'),
  children: [
    // 数据统计
    {
      path: 'dataCenter',
      name: 'DataCenter',
      component: () => import('@/views/Pages/HomeCare/dataCenter'),
      meta: {
        title: '数据中心',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'elderlyDataCenter',
      name: 'elderlyDataCenter',
      component: () => import('@/views/Pages/assessManage/dataCenter/index'),
      meta: {
        title: '数据报告',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'personManage/personManage',
      name: 'PersonManage',
      component: () => import('@/views/Pages/HomeCare/PersonManage/PersonManage/list'),
      meta: {
        title: '长者档案',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'personManage/addPerson/:type/:id?',
      name: 'AddPerson',
      component: () => import('@/views/Pages/HomeCare/PersonManage/PersonManage/addOrEdit'),
      meta: {
        title: '长者档案',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'personManage/personDetail/:id?',
      name: 'PersonDetail',
      component: () => import('@/views/Pages/HomeCare/PersonManage/PersonManage/detail'),
      meta: {
        title: '长者档案',
        keepAlive: false
      }
    },
    {
      path: 'personManage/serveRecords',
      name: 'PersonServeRecords',
      component: () => import('@/views/Pages/HomeCare/PersonManage/ServeRecords/list'),
      meta: {
        title: '服务记录',
        keepAlive: false
      }
    },
    {
      path: 'visitingStatistics',
      name: 'visitingStatistics',
      component: () => import('@/views/Pages/assessManage/visitingStatistics/index'),
      meta: {
        title: '探访统计',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'serviceStatistics',
      name: 'serviceStatistics',
      component: () => import('@/views/Pages/assessManage/serviceStatistics/index'),
      meta: {
        title: '服务统计',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'workPerson',
      name: 'WorkPerson',
      component: () => import('@/views/Pages/HomeCare/WorkPerson/list'),
      meta: {
        title: '从业人员',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'workPerson1',
      name: 'WorkPerson1',
      component: () => import('@/views/Pages/HomeCare/WorkPerson/list'),
      meta: {
        title: '从业人员',
        keepAlive: false
      }
    },
    {
      path: 'workPerson/detail/:id?',
      name: 'WorkPersonDetail',
      component: () => import('@/views/Pages/HomeCare/WorkPerson/detail'),
      meta: {
        title: '从业人员',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'workPerson/:type/:id?',
      name: 'AddWorkPerson',
      component: () => import('@/views/Pages/HomeCare/WorkPerson/addOrEdit'),
      meta: {
        title: '从业人员',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'serveRecords',
      name: 'ServeRecords',
      component: () => import('@/views/Pages/HomeCare/ServeRecords/list'),
      meta: {
        title: '服务记录',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'caringPlatform',
      name: 'caringPlatform',
      component: () => import('@/views/Pages/caringPlatform/caringPlatform'),
      meta: {
        title: '关爱平台',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'oldPersonList',
      name: 'caringPlatform',
      component: () => import('@/views/Pages/caringPlatform/oldPersonList'),
      meta: {
        title: '关爱平台',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'serveRecords/:type/:id?',
      name: 'AddServeRecords',
      component: () => import('@/views/Pages/HomeCare/ServeRecords/addOrEdit'),
      meta: {
        title: '服务记录',
        keepAlive: false
      }
    },
    {
      path: 'addRevisit/:type/:id?',
      name: 'AddRevisit',
      component: () => import('@/views/Pages/HomeCare/ServeRecords/addRevisit'),
      meta: {
        title: '服务记录',
        keepAlive: false
      }
    },
    {
      path: 'serRecordDetail/:type/:id?',
      name: 'SerRecordDetail',
      component: () => import('@/views/Pages/HomeCare/ServeRecords/serRecordDetail'),
      meta: {
        title: '服务记录',
        keepAlive: false
      }
    },
    {
      path: 'serveOrgs',
      name: 'ServeOrgs',
      component: () => import('@/views/Pages/HomeCare/ServeOrgs/list'),
      meta: {
        title: '服务企业',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'serveOrgs/save/:type/:id?',
      name: 'AddServeOrgs',
      component: () => import('@/views/Pages/HomeCare/ServeOrgs/addOrEdit'),
      meta: {
        title: '服务企业',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'serveOrgs/:type/:id?',
      name: 'ServeOrgsDetail',
      component: () => import('@/views/Pages/HomeCare/ServeOrgs/detail'),
      meta: {
        title: '服务企业',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'serveOrgs/addActivityRecord/:id?',
      name: 'AddActivityRecord',
      component: () => import('@/views/Pages/HomeCare/ServeOrgs/components/addActivityRecord'),
      meta: {
        title: '服务企业',
        keepAlive: false
      }
    },

    {
      path: 'happyHouse',
      name: 'HappyHouse',
      component: () => import('@/views/Pages/HomeCare/HappyHouse/index'),
      meta: {
        title: '农村幸福院',
        keepAlive: false
      }
    },
    {
      path: 'happyHouse/save/:type/:id?',
      name: 'AddHappyHouse',
      component: () => import('@/views/Pages/HomeCare/HappyHouse/addOrEdit'),
      meta: {
        title: '农村幸福院',
        keepAlive: false
      }
    },
    {
      path: 'happyHouse/detail/:id?',
      name: 'HappyHouseDetail',
      component: () => import('@/views/Pages/HomeCare/HappyHouse/detail'),
      meta: {
        title: '农村幸福院',
        keepAlive: false
      }
    },

    {
      path: 'personManage/addPubHealthReport/:type/:id?',
      name: 'AddPubHealthReport',
      component: () => import('@/views/Pages/HomeCare/PersonManage/PersonManage/HealthReport/addOrEdit'),
      meta: {
        title: '健康管理报告',
        keepAlive: false
      }
    },
    {
      path: 'personManage/pubHealthReport/detail',
      name: 'PubHealthReportDetail',
      component: () => import('@/views/Pages/HomeCare/PersonManage/PersonManage/HealthReport/detail'),
      meta: {
        title: '健康管理报告',
        keepAlive: false
      }
    },
    {
      path: 'personManage/personManage/serverRecodeTxt',
      name: 'ServerRecodeTxt',
      component: () => import('@/views/Pages/HomeCare/PersonManage/PersonManage/serverRecodeTxt'),
      meta: {
        title: '养老顾问服务报告',
        keepAlive: false
      }
    }
  ]
}

export default routes
