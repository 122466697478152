const routes = {
  path: '/device',
  name: 'Device',
  meta: {
    title: '设备管理',
    keepAlive: false
  },
  redirect: {
    name: 'Device'
  },
  component: () => import('@/views/Layout/Main.vue'),
  children: [
    {
      path: 'device/device',
      name: 'DeviceDevice',
      component: () => import('@/views/Pages/device/device'),
      meta: {
        title: '设备管理',
        keepAlive: false,
        isAuthRoute: true
      }
    }
  ]
}

export default routes
