const routes = {
  path: '/config',
  name: 'Config',
  meta: {
    title: '配置',
    keepAlive: false
  },
  redirect: {
    name: 'Config'
  },
  component: () => import('@/views/Layout/Main.vue'),
  children: [
    {
      path: 'config/miniappMenu',
      name: 'ConfigMiniappMenu',
      component: () => import('@/views/Pages/config/miniappMenu'),
      meta: {
        title: '小程序导航',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'config/miniappBanner',
      name: 'ConfigMiniappBanner',
      component: () => import('@/views/Pages/config/miniappBanner'),
      meta: {
        title: '小程序Banner',
        keepAlive: false,
        isAuthRoute: true
      }
    }
  ]
}

export default routes
