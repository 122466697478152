import API from '@/store/API/api'
import Axios from '@/store/API/axios'

export default {
  // 党组织列表
  partyOrganizationPublicPageList({ state }, params) {
    return Axios.post(`${API.partyOrganizationPublicPageList}`, params)
  },
  // 新增党组织
  partyOrganizationAdd({ state }, params) {
    return Axios.post(`${API.partyOrganizationAdd}`, params)
  },
  // 修改党组织
  partyOrganizationUpdate({ state }, params) {
    return Axios.post(`${API.partyOrganizationUpdate}`, params)
  },

  // 党员列表
  partyPageList({ state }, params) {
    return Axios.post(`${API.partyPageList}`, params)
  }
}
