import API from '@/store/API/api'
import Axios from '@/store/API/axios'

const NursingHome = {
  // 养老院列表
  nursingHomePublicPageList({ state }, params) {
    return Axios.post(`${API.nursingHomePublicPageList}`, params)
  },
  // 新增养老院
  nursingHomeAdd({ state }, params) {
    return Axios.post(`${API.nursingHomeAdd}`, params)
  },
  // 养老院详情
  nursingHomePublicDetail({ state }, params) {
    return Axios.post(`${API.nursingHomePublicDetail}`, params)
  },
  // 修改养老院
  nursingHomeUpdate({ state }, params) {
    return Axios.post(`${API.nursingHomeUpdate}`, params)
  },

  // 获取服务站列表
  getServceStationList({ state }, params) {
    return Axios.post(`${API.getServceStationList}`, params)
  },

  // 获取从业人员列表
  getPractitionerList({ state }, params) {
    return Axios.post(`${API.getPractitionerList}`, params)
  },
  // 修改或新增从业人员
  addOrSavePractitioner({ state }, params) {
    return Axios.post(`${API.addOrSavePractitioner}`, params)
  },
  // 删除从业人员
  deletePractitioner({ state }, params) {
    return Axios.get(`${API.deletePractitioner}/${params.id}`, params)
  },
  // 从业人员详情
  detailPractitioner({ state }, params) {
    return Axios.get(`${API.detailPractitioner}/${params.id}`, params)
  },
  // 获取服务站点信息
  queryServiceSitePage({ state }, params) {
    return Axios.post(`${API.queryServiceSitePage}`, params)
  },
  // 修改或新增服务站
  addOrSaveServiceStation({ state }, params) {
    return Axios.post(`${API.addOrSaveServiceStation}`, params)
  },
  // 服务站详情
  detailServiceStation({ state }, params) {
    return Axios.get(`${API.detailServiceStation}/${params.id}`, params)
  },
  // 删除服务站
  deleteServiceStation({ state }, params) {
    return Axios.get(`${API.deleteServiceStation}/${params.id}`, params)
  },
  // 获取运营日报
  getOperationDailyLst({ state }, params) {
    return Axios.post(`${API.getOperationDailyLst}`, params)
  },

  // 获取日照中心列表
  getSumCenterList({ state }, params) {
    return Axios.post(`${API.getSumCenterList}`, params)
  },
  // 修改或新增日照中心
  addOrSaveSumCenter({ state }, params) {
    return Axios.post(`${API.addOrSaveSumCenter}`, params)
  },
  // 删除日照中心
  deleteSumCenter({ state }, params) {
    return Axios.get(`${API.deleteSumCenter}/${params.id}`, params)
  },

  detailSumCenter({ state }, params) {
    return Axios.get(`${API.detailSumCenter}/${params.id}`, params)
  },
  // 获取综合养老服务中心列表
  getGeneralServiceCenterList({ state }, params) {
    return Axios.post(`${API.getGeneralServiceCenterList}`, params)
  },
  // 修改或新增综合养老服务中心
  addOrSaveGeneralServiceCenter({ state }, params) {
    return Axios.post(`${API.addOrSaveGeneralServiceCenter}`, params)
  },
  // 删除综合养老服务中心
  deleteGeneralServiceCenter({ state }, params) {
    return Axios.get(`${API.deleteGeneralServiceCenter}/${params.id}`, params)
  },
  // 综合养老服务中心详情
  detailGeneralServiceCenter({ state }, params) {
    return Axios.get(`${API.detailGeneralServiceCenter}/${params.id}`, params)
  },
  // 获取社区医疗机构列表
  getMedicalInstList({ state }, params) {
    return Axios.post(`${API.getMedicalInstList}`, params)
  },
  // 修改或新增社区医疗机构
  addOrSaveMedicalInst({ state }, params) {
    return Axios.post(`${API.addOrSaveMedicalInst}`, params)
  },
  // 删除社区医疗机构
  deleteMedicalInst({ state }, params) {
    return Axios.get(`${API.deleteMedicalInst}/${params.id}`, params)
  },
  // 社区医疗机构详情
  detailMedicalInst({ state }, params) {
    return Axios.get(`${API.detailMedicalInst}/${params.id}`, params)
  },

  // 获取核心服务列表
  getCnCoreSeriveList({ state }, params) {
    return Axios.post(`${API.getCnCoreSeriveList}`, params)
  },
  // 修改或新增核心服务
  addOrSaveCnCoreSerive({ state }, params) {
    return Axios.post(`${API.addOrSaveCnCoreSerive}`, params)
  },
  // 删除核心服务
  deleteCnCoreSerive({ state }, params) {
    return Axios.get(`${API.deleteCnCoreSerive}/${params.id}`, params)
  },
  // 核心服务详情
  detailCnCoreSerive({ state }, params) {
    return Axios.get(`${API.detailCnCoreSerive}/${params.id}`, params)
  },
  // 获取服务记录列表
  getServiceRecordList({ state }, params) {
    return Axios.post(`${API.getServiceRecordList}`, params)
  },
  // 修改或新增服务记录
  addOrSaveServiceRecord({ state }, params) {
    return Axios.post(`${API.addOrSaveServiceRecord}`, params)
  },
  // 删除服务记录
  deleteServiceRecord({ state }, params) {
    return Axios.get(`${API.deleteServiceRecord}/${params.id}`, params)
  },
  // 服务记录详情
  detailServiceRecord({ state }, params) {
    return Axios.get(`${API.detailServiceRecord}/${params.id}`, params)
  },
  // 服务记录回访
  addRevisit({ state }, params) {
    return Axios.post(`${API.addRevisit}`, params)
  },
  // 获取服务对象列表
  getServiceObjectList({ state }, params) {
    return Axios.post(`${API.getServiceObjectList}`, params)
  },

  // 机构养老数据中心
  orgDataCenterQueryData({ state }, params) {
    return Axios.get(`${API.orgDataCenterQueryData}`)
  },

  // 机构养老数据中心
  nursingHomeQueryData({ state }, params) {
    return Axios.get(`${API.nursingHomeQueryData}`)
  },

  // 通话记录
  getCallRecordsList({ state }, params) {
    return Axios.post(`${API.getCallRecordsList}`, params)
  },
  // 未接来电记录
  getMissCallList({ state }, params) {
    return Axios.post(`${API.getMissCallList}`, params)
  },
  getServiceCallList({ state }, params) {
    return Axios.post(`${API.getServiceCallList}`, params)
  },
  // 下载录音文件
  getwebCallRecordFile({ state }, params) {
    return Axios.get(`${API.getwebCallRecordFile}/${params.type}/${params.id}`)
  },

  // 呼叫报表
  getCallTableList({ state }, params) {
    return Axios.post(`${API.getCallTableList}`, params)
  },

  // 老人签到列表
  getOldPersonSignList({ state }, params) {
    return Axios.post(`${API.oldPersonSignList}`, params)
  },

  // 从业人员签到列表
  getWorkPersonSignList({ state }, params) {
    return Axios.post(`${API.workPersonSignList}`, params)
  },

  // 老人签到打卡
  getOldPsersonSign({ state }, params) {
    return Axios.post(`${API.oldPsersonSign}`, params)
  },

  // 老人下所有监控列表
  queryPersonMoniterList({ state }, params) {
    return Axios.get(`${API.queryPersonMoniterList}/${params.pubId}`, params)
  },
  // 添加老人监控
  addPersonMoniter({ state }, params) {
    return Axios.post(`${API.addPersonMoniter}`, params)
  },
  // 删除老人监控
  delPersonMoniter({ state }, params) {
    return Axios.get(`${API.delPersonMoniter}/${params.id}`, params)
  },
  
  YSPersonAccessToken({ state }, params) {
    return Axios.get(`${API.YSPersonAccessToken}/${params.id}`, params)
  },
  // 从业人员签到打卡
  getworkPersonSign({ state }, params) {
    return Axios.post(`${API.workPersonSign}`, params)
  },

  // 日渐照料---运营日报列表
  cmOperationDailyList({ state }, params) {
    return Axios.post(`${API.cmOperationDailyList}`, params)
  },

  // 日照下所有监控列表
  queryMoniterList({ state }, params) {
    return Axios.get(`${API.queryMoniterList}/${params.cmId}`, params)
  },
  // 添加监控
  addMoniters({ state }, params) {
    return Axios.post(`${API.addMoniter}`, params)
  },
  // 删除监控
  delMoniter({ state }, params) {
    return Axios.get(`${API.delMoniter}/${params.id}`, params)
  },

  YSAccessToken({ state }, params) {
    return Axios.get(`${API.YSAccessToken}/${params.id}`, params)
  },

  // 资产列表查询

  queryAssetList({ state }, params) {
    return Axios.post(`${API.queryAssetList}`, params)
  },
  // 新增资产信息
  addAssset({ state }, params) {
    return Axios.post(`${API.addAssset}`, params)
  },
  // 修改资产信息
  updateAssset({ state }, params) {
    return Axios.post(`${API.updateAssset}`, params)
  },
  // 删除
  delAsset({ state }, params) {
    return Axios.post(`${API.delAsset}/${params.id}`, params)
  },

  // 打扫卫生列表查询
  queryClearningList({ state }, params) {
    return Axios.post(`${API.queryClearningList}`, params)
  },

  // 新增消杀
  addcleanList({ state }, params) {
    return Axios.post(`${API.addcleanList}`, params)
  },
  // 修改消杀
  updateCleanList({ state }, params) {
    return Axios.post(`${API.updateCleanList}`, params)
  },
  // 删除消杀
  deleteClean({ state }, params) {
    return Axios.post(`${API.deleteClean}/${params.id}`, params)
  },
  // 查消杀列表
  queryCleanList({ state }, params) {
    return Axios.post(`${API.queryCleanList}`, params)
  },
  // 查消杀详情
  cleanDetail({ state }, params) {
    return Axios.post(`${API.cleanDetail}/${params.id}`, params)
  }

}

export default NursingHome
