const routes = {
  path: '/feedback',
  name: 'Feedback',
  meta: {
    title: '服务直通车管理',
    keepAlive: false
  },
  redirect: {
    name: 'Feedback'
  },
  component: () => import('@/views/Layout/Main.vue'),
  children: [
    {
      path: 'feedback/feedback',
      name: 'FeedbackFeedback',
      component: () => import('@/views/Pages/feedback/feedback'),
      meta: {
        title: '意见反馈',
        keepAlive: false,
        isAuthRoute: true
      }
    }
  ]
}

export default routes
