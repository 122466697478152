import API from '@/store/API/api'
import Axios from '@/store/API/axios'

export default {
  // 审核
  auditAudit({ state }, params) {
    return Axios.post(`${API.auditAudit}`, params)
  },

  // 用户列表
  pubuserPageList({ state }, params) {
    return Axios.post(`${API.pubuserPageList}`, params)
  }
}
