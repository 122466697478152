const routes = {
  path: '/task',
  name: 'Task',
  meta: {
    title: '任务管理',
    keepAlive: false
  },
  redirect: {
    name: 'Task'
  },
  component: () => import('@/views/Layout/Main.vue'),
  children: [
    {
      path: 'task/task',
      name: 'TaskTask',
      component: () => import('@/views/Pages/task/task'),
      meta: {
        title: '任务管理',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'task/activity',
      name: 'TaskActivity',
      component: () => import('@/views/Pages/task/activity'),
      meta: {
        title: '活动管理',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'task/releaseRecord/:initiatorUserId',
      name: 'TaskReleaseRecord',
      component: () => import('@/views/Pages/task/task/components/releaseRecord'),
      meta: {
        title: '任务-发布记录',
        keepAlive: false
      }
    },
    {
      path: 'task/participationRecord/:claimantUserId',
      name: 'TaskParticipationRecord',
      component: () => import('@/views/Pages/task/task/components/participationRecord'),
      meta: {
        title: '任务-参与记录',
        keepAlive: false
      }
    },
    {
      path: 'task/participationPersonnel/:taskId',
      name: 'TaskParticipationPersonnel',
      component: () => import('@/views/Pages/task/task/components/participationPersonnel'),
      meta: {
        title: '任务-参与人员',
        keepAlive: false
      }
    }
  ]
}

export default routes
