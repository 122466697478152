const routes = {
  path: '/audit',
  name: 'Audit',
  meta: {
    title: '审核管理',
    keepAlive: false
  },
  redirect: {
    name: 'Audit'
  },
  component: () => import('@/views/Layout/Main.vue'),
  children: [
    {
      path: 'audit/nursingHome',
      name: 'AuditNursingHome',
      component: () => import('@/views/Pages/audit/nursingHome'),
      meta: {
        title: '养老院',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'audit/sumCenter',
      name: 'AuditSumCenter',
      component: () => import('@/views/Pages/audit/sumCenter'),
      meta: {
        title: '日间照料中心',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'audit/seniorMeal',
      name: 'AuditSeniorMeal',
      component: () => import('@/views/Pages/audit/seniorMeal'),
      meta: {
        title: '老年餐机构',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'audit/neighborhood',
      name: 'AuditNeighborhood',
      component: () => import('@/views/Pages/audit/neighborhood'),
      meta: {
        title: '邻里圈',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'audit/enterprise',
      name: 'AuditEnterprise',
      component: () => import('@/views/Pages/audit/enterprise'),
      meta: {
        title: '服务企业',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'audit/realName',
      name: 'AuditRealName',
      component: () => import('@/views/Pages/audit/realName'),
      meta: {
        title: '实名认证',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'audit/volunteer',
      name: 'AuditVolunteer',
      component: () => import('@/views/Pages/audit/volunteer'),
      meta: {
        title: '志愿者',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'audit/party',
      name: 'AuditParty',
      component: () => import('@/views/Pages/audit/party'),
      meta: {
        title: '党员',
        keepAlive: false,
        isAuthRoute: true
      }
    }
  ]
}

export default routes
