const routes = {
  path: '/agedEat',
  name: 'AgedEat',
  meta: {
    title: '老年餐',
    keepAlive: false
  },
  redirect: {
    name: 'AgedEat'
  },
  component: () => import('@/views/Layout/Main.vue'),
  children: [
    {
      path: 'agedEat/workPerson',
      name: 'OperateDailys',
      component: () => import('@/views/Pages/agedEat/mechaismManage/operateDaily/list'),
      meta: {
        title: '用餐记录',
        keepAlive: false
      }
    },
    {
      path: 'agedEat/dataCenter',
      name: 'AgedEatDataCenter',
      component: () => import('@/views/Pages/agedEat/dataCenter/list'),
      meta: {
        title: '数据中心',
        keepAlive: false
      }
    },

    {
      path: 'agedEat/manManage/manManage',
      name: 'ManManage',
      component: () => import('@/views/Pages/agedEat/manManage/manManage/list'),
      meta: {
        title: '人员管理',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'agedEat/manManage/manManage',
      name: 'ManManageDetail',
      component: () => import('@/views/Pages/agedEat/manManage/manManage/detail'),
      meta: {
        title: '人员管理',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'agedEat/manManage/manManage/:type/:id?',
      name: 'AddPersonnelManage',
      component: () => import('@/views/Pages/agedEat/manManage/manManage/addOrEdit'),
      meta: {
        title: '操作人员管理',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'agedEat/manManage/MealRecord',
      name: 'MealRecord',
      component: () => import('@/views/Pages/agedEat/manManage/MealRecord/list'),
      meta: {
        title: '用餐记录',
        keepAlive: false
      }
    },
    {
      path: 'agedEat/mechaismManage',
      name: 'AgedEatMechaismManage',
      component: () => import('@/views/Pages/agedEat/mechaismManage/mechaismManage/list'),
      meta: {
        title: '机构管理',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'agedEat/mechaismManage/:type/:id?',
      name: 'AddAgedEatMechaismManage',
      component: () => import('@/views/Pages/agedEat/mechaismManage/mechaismManage/addOrEdit'),
      meta: {
        title: '机构管理',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'agedEat/mechaismManageDetail/:type/:id?',
      name: 'MechaismDetail',
      component: () => import('@/views/Pages/agedEat/mechaismManage/mechaismManage/detail'),
      meta: {
        title: '机构管理',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'agedEat/workPerson',
      name: 'OperateDaily',
      component: () => import('@/views/Pages/agedEat/mechaismManage/operateDaily/list'),
      meta: {
        title: '运营日报',
        keepAlive: false,
        isAuthRoute: true
      }
    },

    {
      path: 'agedEat/evaluationAgency',
      name: 'EvaluationAgency',
      component: () => import('@/views/Pages/agedEat/evaluationAgency/index'),
      meta: {
        title: '用餐记录',
        keepAlive: false
      }
    },
    {
      path: 'agedEat/cardManage',
      name: 'MealCard',
      component: () => import('@/views/Pages/agedEat/mealCard/cardManage/cardList'),
      meta: {
        title: '饭卡管理',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'agedEat/addCard',
      name: 'AddCard',
      component: () => import('@/views/Pages/agedEat/mealCard/cardManage/addCard'),
      meta: {
        title: '饭卡管理',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'agedEat/rechangeList',
      name: 'RechangeList',
      component: () => import('@/views/Pages/agedEat/mealCard/rechargeCard/rechangeList'),
      meta: {
        title: '饭卡管理',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'agedEat/addTrans',
      name: 'AddTrans',
      component: () => import('@/views/Pages/agedEat/mealCard/rechargeCard/addTrans'),
      meta: {
        title: '饭卡管理',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'agedEat/destoryCard',
      name: 'DestoryCard',
      component: () => import('@/views/Pages/agedEat/mealCard/rechargeCard/destoryCard'),
      meta: {
        title: '饭卡管理',
        keepAlive: false,
        isAuthRoute: true
      }
    }
  ]
}

export default routes
