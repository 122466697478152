import API from '@/store/API/api'
import Axios from '@/store/API/axios'

const HomeCare = {
  // 获取居家养老-数据统计-人员信息统计
  initElderData({ state }, params) {
    return Axios.get(`${API.initElderData}`)
  },
  // 获取居家养老-数据统计-企业信息统计
  initServiceCompanyData({ state }, params) {
    return Axios.get(`${API.initServiceCompanyData}`)
  },
  // 获取居家养老-人员管理-人员管理列表
  getHomePersonListServe({ state }, params) {
    return Axios.post(`${API.getHomePersonList}`, params)
  },
  getHomePersonNewListServe({ state }, params) {
    return Axios.post(`${API.getHomePersonNewList}`, params)
  },
  // 复制老人信息
  copyOldPerson({ state }, params) {
    return Axios.post(`${API.copyOldPerson}`, params)
  },
  // 添加人员
  addHomeOldPersonServe({ state }, params) {
    return Axios.post(`${API.addHomeOldPerson}`, params)
  },
  // 删除人员
  delHomeOldPersonServe({ state }, params) {
    return Axios.get(`${API.delHomeOldPerson}/${params.id}`)
  },
  detailHomeOldPersonServe({ state }, params) {
    return Axios.get(`${API.detailHomeOldPerson}/${params.id}`)
  },
  // 导入数据
  importHomeOldPersonServe({ state }, params) {
    return Axios.post(`${API.importHomeOldPerson}`, params)
  },
  getServicePersonInfoServe({ state }, params) {
    return Axios.post(`${API.getServicePersonInfo}`, params)
  },
  // 入住养老机构列表
  getPersonOrgListServe({ state }, params) {
    return Axios.post(`${API.getPersonOrgList}`, params)
  },
  getPersonOrgListServePub({ state }, params) {
    return Axios.post(`${API.getPersonOrgListpub}`, params)
  },

  // 获取居家养老-从业人员列表
  getWorkPersonListServe({ state }, params) {
    return Axios.post(`${API.getWorkPersonList}`, params)
  },
  // 某个机构的-从业人员列表
  getWorkPersonListServe2({ state }, params) {
    return Axios.post(`${API.getWorkPersonList2}`, params)
  },
  // 添加从业人员
  addWorkPersonServe({ state }, params) {
    return Axios.post(`${API.addWorkPerson}`, params)
  },
  // 修改从业人员
  updateWorkPersonServe({ state }, params) {
    return Axios.post(`${API.updateWorkPerson}`, params)
  },
  // 删除从业人员
  delWorkPersonServe({ state }, params) {
    return Axios.get(`${API.delWorkPerson}/${params.id}`)
  },

  detailWorkPersonServe({ state }, params) {
    return Axios.post(`${API.detailWorkPerson}/${params.id}`, params)
  },

  // 居家养老——服务记录
  getServeRecordsListServe({ state }, params) {
    return Axios.post(`${API.getServeRecordsList}`, params)
  },
  addServeRecordsServe({ state }, params) {
    return Axios.post(`${API.addServeRecords}`, params)
  },
  updateServeRecordsServe({ state }, params) {
    return Axios.post(`${API.updateServeRecords}`, params)
  },
  delServeRecordsServe({ state }, params) {
    return Axios.get(`${API.delServeRecords}/${params.id}`)
  },
  getServeMonthPageListServe({ state }, params) {
    return Axios.post(`${API.getServeMonthPageList}`, params)
  },
  detailServeRecordsServe({ state }, params) {
    return Axios.get(`${API.detailServeRecords}/${params.id}`)
  },
  // 获取服务员工
  getAllPageListServe({ state }, params) {
    return Axios.post(`${API.getAllPageList}`, params)
  },

  // 农村幸福院列表
  getCountryStationList({ state }, params) {
    return Axios.post(`${API.getCountryStationList}`, params)
  },
  // 修改或新增农村幸福院
  addOrSaveCountryStation({ state }, params) {
    return Axios.post(`${API.addOrSaveCountryStation}`, params)
  },
  // 农村幸福院详情
  detailCountryStation({ state }, params) {
    return Axios.get(`${API.detailCountryStation}/${params.id}`, params)
  },
  // 删除农村幸福院
  deleteCounryStation({ state }, params) {
    return Axios.get(`${API.deleteCounryStation}/${params.id}`, params)
  },

  // 居家养老——服务企业
  getServeOrgsListServe({ state }, params) {
    return Axios.post(`${API.getServeOrgsList}`, params)
  },
  getServeOrgsList1({ state }, params) {
    return Axios.post(`${API.getServeOrgsList1}`, params)
  },
  addServeOrgsServe({ state }, params) {
    return Axios.post(`${API.addServeOrgs}`, params)
  },
  updateOrgsServe({ state }, params) {
    return Axios.post(`${API.updateOrgs}`, params)
  },
  delServeOrgsServe({ state }, params) {
    return Axios.get(`${API.delServeOrgs}/${params.id}`)
  },
  detailServeOrgsServe({ state }, params) {
    return Axios.get(`${API.detailServeOrgs}/${params.id}`)
  },

  // 居家养老——核心服务
  getHomeCoreSeriveListServe({ state }, params) {
    return Axios.post(`${API.getHomeCoreSeriveList}`, params)
  },
  addHomeCoreSeriveServe({ state }, params) {
    return Axios.post(`${API.addHomeCoreSerive}`, params)
  },
  delHomeCoreSeriveServe({ state }, params) {
    return Axios.get(`${API.delHomeCoreSerive}/${params.id}`)
  },

  // 获取居家养老-充值记录
  getMoneyRecordListServe({ state }, params) {
    return Axios.post(`${API.getMoneyRecordList}`, params)
  },
  addMoneyRecordServe({ state }, params) {
    return Axios.post(`${API.addMoneyRecord}`, params)
  },
  updateMoneyRecordServe({ state }, params) {
    return Axios.post(`${API.updateMoneyRecord}`, params)
  },
  delMoneyRecordServe({ state }, params) {
    return Axios.get(`${API.delMoneyRecord}/${params.id}`)
  },
  queryMoneyPageByPersonServe({ state }, params) {
    return Axios.post(`${API.queryMoneyPageByPerson}`, params)
  },

  // 增加健康管理报告
  addPubHealthReport({ state }, params) {
    return Axios.post(`${API.addPubHealthReport}`, params)
  },
  // 删除健康管理报告
  deletePubHealthReport({ state }, params) {
    return Axios.get(`${API.deletePubHealthReport}/${params.id}`, params)
  },
  // 健康管理报告列表
  getPubHealthReportList({ state }, params) {
    return Axios.post(`${API.getPubHealthReportList}`, params)
  },
  // 更新健康管理报告
  updatePubHealthReport({ state }, params) {
    return Axios.post(`${API.updatePubHealthReport}`, params)
  },
  // 居家养老数据中心
  homeCenterQueryData({ state }, params) {
    return Axios.get(`${API.homeCenterQueryData}`)
  },

  // 查询时间银行
  queryElectLocks({ state }, params) {
    return Axios.post(`${API.queryElectLocks}`, params)
  },

  // 服务记录列表查询
  queryhomeServiceRecordList({ state }, params) {
    return Axios.post(`${API.homeServiceRecordList}`, params)
  },
  // 服务记录列表导出
  homeServiceRecordExport4({ state }, params) {
    return Axios.post(`${API.homeServiceRecordExport4}`, params)
  },
  // 导出服务记录图片
  homeServiceRecordExportImg({ state }, params) {
    return Axios.post(`${API.homeServiceRecordExportImg}`, params)
  },
  // 添加活动记录

  addActRecord({ state }, params) {
    return Axios.post(`${API.addActRecord}`, params)
  },
  // 活动记录列表
  queryActRecoedList({ state }, params) {
    return Axios.post(`${API.actRecordList}`, params)
  },
  // 活动记录详情
  actDetail({ state }, params) {
    return Axios.get(`${API.actDetail}/${params.id}`)
  },
  updataActRecord({ state }, params) {
    return Axios.post(`${API.updataActRecord}`, params)
  },

  // 活动记录 查询从业人员（弹窗）
  getWorkPersonPup({ state }, params) {
    return Axios.post(`${API.getWorkPersonPup}`, params)
  },
  deleteActRecord({ state }, params) {
    return Axios.get(`${API.deleteActRecord}/${params.id}`)
  },
  // 输入框搜索记录
  serchRecord({ state }, params) {
    return Axios.post(`${API.serchRecord}`, params)
  },
  // 适老化改造
  elderReformDetai({ state }, params) {
    return Axios.post(`${API.elderReformDetai}`, params)
  },
  // 适老化改造
  elderList({ state }, params) {
    return Axios.post(`${API.elderList}`, params)
  },
  // 适老化改造导出
  elderListExport({ state }, params) {
    return Axios.post(`${API.elderListExport}`, params)
  },
  // 适老化数据中心
  elderDataCenter({ state }, params) {
    return Axios.post(`${API.elderDataCenter}`, params)
  },
  // 探访统计
  visitingStatistics({ state }, params) {
    return Axios.post(`${API.visitingStatistics}`, params)
  },
  // 服务统计
  serviceStatistics({ state }, params) {
    return Axios.post(`${API.serviceStatistics}`, params)
  },
  // 信息确认详情
  basicDetail({ state }, params) {
    return Axios.post(`${API.basicDetail}`, params)
  },
  // 信息确认添加
  basicAdd({ state }, params) {
    return Axios.post(`${API.basicAdd}`, params)
  },
  // 信息确认修改
  basicUpdate({ state }, params) {
    return Axios.post(`${API.basicUpdate}`, params)
  },
  // 信息确认删除
  basicDelete({ state }, params) {
    return Axios.post(`${API.basicDelete}/${params}`, params)
  },

  // 老人评估详情
  personDetail({ state }, params) {
    return Axios.post(`${API.personDetail}`, params)
  },
  // 老人评估删除
  personDelete({ state }, params) {
    return Axios.post(`${API.personDelete}/${params}`, params)
  },

  // 改造申请详情
  applyAdd({ state }, params) {
    return Axios.post(`${API.applyAdd}`, params)
  },

  // 改造申请详情
  applyUpdate({ state }, params) {
    return Axios.post(`${API.applyUpdate}`, params)
  },

  // 改造申请详情
  applyDelete({ state }, params) {
    return Axios.post(`${API.applyDelete}/${params}`, params)
  },

  // 改造申请详情
  applyDetail({ state }, params) {
    return Axios.post(`${API.applyDetail}`, params)
  },

  // 需求确认详情
  requireAdd({ state }, params) {
    return Axios.post(`${API.requireAdd}`, params)
  },

  // 需求确认详情
  requireUpdate({ state }, params) {
    return Axios.post(`${API.requireUpdate}`, params)
  },

  // 需求确认详情
  requireDetail({ state }, params) {
    return Axios.post(`${API.requireDetail}`, params)
  },

  // 需求确认详情
  requireDelete({ state }, params) {
    return Axios.post(`${API.requireDelete}/${params}`, params)
  },

  // 改造施工详情
  constructDetail({ state }, params) {
    return Axios.post(`${API.constructDetail}`, params)
  },

  // 改造施工删除
  constructDelete({ state }, params) {
    return Axios.post(`${API.constructDelete}/${params}`, params)
  },

  // 需求验收详情
  acceptDetail({ state }, params) {
    return Axios.post(`${API.acceptDetail}/${params.id}`, params)
  },

  // 需求验收详情
  acceptDelete({ state }, params) {
    return Axios.post(`${API.acceptDelete}/${params}`, params)
  },

  // 放弃承诺书详情
  giveUpDetail({ state }, params) {
    return Axios.post(`${API.giveUpDetail}`, params)
  },

  // 放弃承诺书详情
  giveUpAdd({ state }, params) {
    return Axios.post(`${API.giveUpAdd}`, params)
  },

  // 放弃承诺书详情
  giveUpUpdate({ state }, params) {
    return Axios.post(`${API.giveUpUpdate}`, params)
  },

  // 放弃承诺书详情
  giveUpDelete({ state }, params) {
    return Axios.post(`${API.giveUpDelete}/${params}`, params)
  },

  // 信息汇总详情
  elderlyOrientedSummaryDetail({ state }, params) {
    return Axios.post(`${API.elderlyOrientedSummaryDetail}`, params)
  },

  // 信息汇总删除
  elderlyOrientedSummaryDelete({ state }, params) {
    return Axios.post(`${API.elderlyOrientedSummaryDelete}/${params}`, params)
  },

  // 项目类型分类
  projectCategoryList({ state }, params) {
    return Axios.get(`${API.projectCategoryList}`, params)
  },

  // 项目类型
  getProjectList({ state }, params) {
    return Axios.post(`${API.getProjectList}`, params)
  },

  // 产品类型
  productCategoryList({ state }, params) {
    return Axios.get(`${API.productCategoryList}`, params)
  },

  // 产品
  getProductList({ state }, params) {
    return Axios.post(`${API.getProductList}`, params)
  },

  // 添加
  addElderReform({ state }, params) {
    return Axios.post(`${API.addElderReform}`, params)
  },
  // 修改
  updateElderReform({ state }, params) {
    return Axios.post(`${API.updateElderReform}`, params)
  },
  deleteElderReform({ state }, params) {
    return Axios.post(`${API.deleteElderReform}/${params}`, params)
  },
  queryolderReformList({ state }, params) {
    return Axios.post(`${API.queryolderReformList}`, params)
  },
  detailWithSensor({ state }, params) {
    return Axios.post(`${API.detailWithSensor}/${params}`, params)
  },
  deviceInfoListByOldPersonId({ state }, params) {
    return Axios.get(`${API.deviceInfoListByOldPersonId}/${params.id}`, params)
  },
  additionalDeviceDelete({ state }, params) {
    return Axios.post(`${API.additionalDeviceDelete}/${params}`, params)
  },
  additionalDeviceAdd({ state }, params) {
    return Axios.post(`${API.additionalDeviceAdd}`, params)
  },
  deviceInfoListByNickname({ state }, params) {
    return Axios.post(`${API.deviceInfoListByNickname}`, params)
  },
  sensorInfoUpdate({ state }, params) {
    return Axios.post(`${API.sensorInfoUpdate}`, params)
  },
  getSysDict({ state }, params) {
    return Axios.get(`${API.getSysDict}/${params.typeCode}`, params)
  },
  deviceInfoUpdate({ state }, params) {
    return Axios.post(`${API.deviceInfoUpdate}`, params)
  },
  deviceInfoBind({ state }, params) {
    return Axios.post(`${API.deviceInfoBind}`, params)
  },
  deviceInfoThirdUnBind({ state }, params) {
    return Axios.post(`${API.deviceInfoThirdUnBind}`, params)
  },

  deviceInfoUnBind({ state }, params) {
    return Axios.post(`${API.deviceInfoUnBind}`, params)
  },

  // 老人体检报告新增
  examReportAdd({ state }, params) {
    return Axios.post(`${API.examReportAdd}`, params)
  },
  // 老人体检报告获取
  getExamReport({ state }, params) {
    return Axios.get(`${API.getExamReport}/${params.id}`, params)
  }
}

export default HomeCare
