import API from '@/store/API/api'
import Axios from '@/store/API/axios'

export default {
  // 设备列表
  devicePageList({ state }, params) {
    return Axios.post(`${API.devicePageList}`, params)
  },
  // 新增设备
  deviceAdd({ state }, params) {
    return Axios.post(`${API.deviceAdd}`, params)
  },
  // 设备详情
  deviceDetail({ state }, params) {
    return Axios.get(`${API.deviceDetail}/${params.id}`, params)
  },
  // 修改设备
  deviceUpdate({ state }, params) {
    return Axios.post(`${API.deviceUpdate}`, params)
  },
  // 删除设备
  deviceDelete({ state }, params) {
    return Axios.post(`${API.deviceDelete}/${params.id}`, params)
  }
}
