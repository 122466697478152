import API from '@/store/API/api'
import Axios from '@/store/API/axios'

export default {
  // 小程序导航分类列表
  miniappMenuCategoryList({ state }, params) {
    return Axios.post(`${API.miniappMenuCategoryList}`, params)
  },
  // 修改小程序导航分类
  miniappMenuCategorySave({ state }, params) {
    return Axios.post(`${API.miniappMenuCategorySave}`, params)
  },
  // 小程序导航列表
  miniappMenuPublicMenuList({ state }, params) {
    return Axios.post(`${API.miniappMenuPublicMenuList}`, params)
  },
  // 修改小程序导航
  miniappMenuMenuSave({ state }, params) {
    return Axios.post(`${API.miniappMenuMenuSave}`, params)
  },

  // 小程序Banner列表
  miniappBannerPublicPageList({ state }, params) {
    return Axios.post(`${API.miniappBannerPublicPageList}`, params)
  },
  // 新增小程序Banner
  miniappBannerAdd({ state }, params) {
    return Axios.post(`${API.miniappBannerAdd}`, params)
  },
  // 小程序Banner详情
  miniappBannerPublicDetail({ state }, params) {
    return Axios.get(`${API.miniappBannerPublicDetail}/${params.id}`, params)
  },
  // 修改小程序Banner
  miniappBannerUpdate({ state }, params) {
    return Axios.post(`${API.miniappBannerUpdate}`, params)
  },
  // 修改小程序Banner
  miniappBannerDelete({ state }, params) {
    return Axios.post(`${API.miniappBannerDelete}/${params.id}`, params)
  }
}
