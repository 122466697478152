const routes = {
  path: '/neighborhood',
  name: 'Neighborhood',
  meta: {
    title: '邻里圈管理',
    keepAlive: false
  },
  redirect: {
    name: 'Neighborhood'
  },
  component: () => import('@/views/Layout/Main.vue'),
  children: [
    {
      path: 'neighborhood/topics',
      name: 'NeighborhoodTopics',
      component: () => import('@/views/Pages/neighborhood/topics'),
      meta: {
        title: '话题',
        keepAlive: false,
        isAuthRoute: true
      }
    },
    {
      path: 'neighborhood/neighborhood',
      name: 'NeighborhoodNeighborhood',
      component: () => import('@/views/Pages/neighborhood/neighborhood'),
      meta: {
        title: '邻里圈',
        keepAlive: false,
        isAuthRoute: true
      }
    }
  ]
}

export default routes
