/*
 * @Author: your name
 * @Date: 2020-05-07 16:01:47
 * @LastEditTime: 2022-03-09 09:59:48
 * @LastEditors: wendan
 * @Description: In User Settings Edit
 * @FilePath: \htjr-web\src\store\API\api.js
 */
// 项目中所有的接口
const API = {
  login: '/user/login', // 登录
  logOut: '/logout', // 退出登录
  logoutByClassbao:'/user/logout',
  loginUserInfo: '/user/loginUserInfo', // 登录用户信息
  downloadImg: '/file/downloadImg', // 下载图片（base64）
  getUserInfo: '/getUserInfo', // 获取登录用户信息
  getSysDict: '/sysDict/list', // 获取数据字典
  getArealist: '/sys-area-info/getArealist', // 获取区县list
  getstrealist: '/sys-area-info/getstrealist', // 获取街道list
  getProvinces: '/sys-area-info/getProvinces', // 获取省
  getCitys: '/sys-area-info/getCitys', // 获取市
  getAreas: '/sys-area-info/getAreas', // 获取区县
  getStreets: '/sys-area-info/getStreets', // 获取区县
  getCommunitys: '/sys-area-info/getCommunitys', // 获取社区
  getCommunityInfo: '/sys-area-info/communityInfoPage', // 获取社区分页列表
  delCommunityInfo: '/sys-area-info/deleteCommunityInfo', // 删除社区列表
  saveCommunityInfo: '/sys-area-info/saveCommunityInfo', // 保存社区列表

  // add by wenxiang.zhang
  // 增加区域分层接口
  // getReGionProvinces:'sys-region/list',
  getReGionByPid: '/sys-region/getAreaByPid', // 根据pid获取地区列表
  getReGionDetail: '/sys-region/detail', // 获取详情
  addRegionServe: '/sys-region/add',
  editRegionServe: '/sys-region/update',
  delRegionServe: '/sys-region/delete',
  // getCitys: "/sys-area-info/getCitys", //获取市
  // getAreas: "/sys-area-info/getAreas", //获取区县
  // getStreets: "/sys-area-info/getStreets", //获取区县
  // getCommunitys: "/sys-area-info/getCommunitys", //获取社区
  // add end

  // 人员信息
  getManInfoList: '/mealPerson/page',
  addmealPersonSave: '/mealPerson/save',
  delMealPerson: '/mealPerson/delete',
  mealPersonInfo: '/mealPerson/detail',
  copyOldPerson: '/cmn/oldPerson/copy',

  fileUpload: '/file/upload', // 上传图片
  fileDetail: '/file/download/', // 文件详情
  // 居家养老
  // 数据统计
  initElderData: '/homeCenter/initElderData', // 人员信息统计
  initServiceCompanyData: '/homeCenter/initServiceCompanyData', // 企业信息统计
  // 人员管理
  getHomePersonList: '/homeOldPerson/page', // 获取人员管理列表
  getHomePersonNewList: '/homeOldPerson/page2', // 获取人员管理列表
  addHomeOldPerson: '/homeOldPerson/save', // 添加人员
  delHomeOldPerson: '/homeOldPerson/delete', // 删除人员
  exportHomeOldPerson: '/homeOldPerson/excel', // 导出人员
  exportElderlyOriented: '/pub-elderly-oriented-reform/require/export-latest-all', // 适老化改造导出
  detailHomeOldPerson: '/homeOldPerson/detail', // 人员详情
  importHomeOldPerson: '/homeOldPerson/import', // 导入人员
  // getServicePersonInfo: "/homeOldPerson/servicePersonInfo", // /获取服务对象
  getServicePersonInfo: '/serviceRecord/serviceRecordSumPage', // 获取服务对象
  getPersonOrgList: '/homeOldPerson/orgPageList2', // 入住养老机构列表
  getPersonOrgListpub: '/homeOldPerson/orgPageList', // 入住养老机构列表
  detailWithSensor: '/bed-device-info/detail-with-sensor', // 老人 设备及相关传感器详情
  deviceInfoListByOldPersonId: '/deviceInfo/listByOldPersonId', // 老人 设备及相关传感器详情
  deviceInfoListByNickname: '/bed-device-info/list-by-nickname', // 老人 设备查询
  additionalDeviceDelete: '/bed-additional-device-info/delete', // 老人 传感器删除
  additionalDeviceAdd: '/bed-additional-device-info/add', // 老人 传感器增加
  sensorInfoUpdate: '/bed-sensor-info/update', // 老人 传感器变更
  deviceInfoUpdate: '/bed-device-info/update', // 老人 设备变更
  // deviceInfoBind: '/bed-device-info/bind', // 老人 设备绑定
  deviceInfoBind: '/bed-device-info/bind', // 老人 设备绑定
  deviceInfoThirdUnBind: '/deviceInfo/unbind', // 老人 解除设备绑定(第三方)
  deviceInfoUnBind: '/bed-device-info/unbind', // 老人 设备解绑

  // 充值记录
  getMoneyRecordList: '/pubOldPersonMoneyRecord/pageList',
  addMoneyRecord: '/pubOldPersonMoneyRecord/save',
  updateMoneyRecord: '/pubOldPersonMoneyRecord/update',
  delMoneyRecord: '/pubOldPersonMoneyRecord/deleteById',
  queryMoneyPageByPerson: '/pubOldPersonMoneyRecord/queryMoneyPage', // 充值记录中查老人详情

  // 从业人员
  getWorkPersonList: '/pubWorkPersonInfo/pageList',
  getWorkPersonList2: '/pubWorkPersonInfo/pageList2',
  addWorkPerson: '/pubWorkPersonInfo/add',
  updateWorkPerson: '/pubWorkPersonInfo/update',
  delWorkPerson: '/pubWorkPersonInfo/deleteById',
  detailWorkPerson: '/pubWorkPersonInfo/detail',

  // 社区养老
  getPractitionerList: '/workPerson/page', // 获取从业人员列表
  addOrSavePractitioner: '/workPerson/save', // 修改或新增从业人员
  deletePractitioner: '/workPerson/delete', // 删除从业人员
  detailPractitioner: '/workPerson/detail', // 从业人员详情
  exportPractitioner: '/workPerson/export', // 导出服务站
  queryServiceSitePage: '/workPerson/queryServiceSitePage', // 获取服务站点信息

  getCountryStationList: '/countrySite/page', // 农村幸福院列表
  addOrSaveCountryStation: '/countrySite/save', // 修改或者新增农村幸福院
  detailCountryStation: '/countrySite/detail', // 农村幸福院详情
  deleteCounryStation: '/countrySite/delete', // 删除服务站农村幸福院
  exportCountryStation: '/countrySite/export', // 导出农村幸福院

  getServceStationList: '/workSite/page', // 获取服务站列表
  addOrSaveServiceStation: '/workSite/save', // 修改或者新增服务站
  detailServiceStation: '/workSite/detail', // 服务站详情
  deleteServiceStation: '/workSite/delete', // 删除服务站
  exportServiceStation: '/workSite/export', // 导出服务站
  getOperationDailyLst: '/workSite/queryOperationDailyReport', // 获取运营日报

  getSumCenterList: '/sumCenter/page', // 获取日照中心列表
  addOrSaveSumCenter: '/sumCenter/save', // 修改或新增日照中心
  deleteSumCenter: '/sumCenter/delete', // 删除日照中心
  detailSumCenter: '/sumCenter/detail', // 日照中心详情
  exportSumCenter: '/sumCenter/export', // 导出日照中心

  getGeneralServiceCenterList: '/generalServiceCenter/page', // 获取综合养老服务中心列表
  addOrSaveGeneralServiceCenter: '/generalServiceCenter/save', // 修改或新增综合养老服务中心
  deleteGeneralServiceCenter: '/generalServiceCenter/delete', // 删除综合养老服务中心
  detailGeneralServiceCenter: '/generalServiceCenter/detail', // 综合养老服务中心详情
  exportGeneralServiceCenter: '/generalServiceCenter/export', // 导出综合养老服务中心

  getMedicalInstList: '/medicalInst/page', // 获取社区医疗机构列表
  addOrSaveMedicalInst: '/medicalInst/save', // 修改或新增综社区医疗机构
  deleteMedicalInst: '/medicalInst/delete', // 删除社区医疗机构
  detailMedicalInst: '/medicalInst/detail', // 社区医疗机构详情

  getCnCoreSeriveList: '/cnCoreSerive/pageList', // 获取核心服务列表
  addOrSaveCnCoreSerive: '/cnCoreSerive/save', // 修改或新增核心服务
  deleteCnCoreSerive: '/cnCoreSerive/delete', // 删除核心服务
  detailCnCoreSerive: '/cnCoreSerive/query', // 核心服务详情

  getServiceRecordList: '/serviceRecord/pageList', // 获取服务记录列表
  addOrSaveServiceRecord: '/serviceRecord/save', // 修改或新增服务记录
  deleteServiceRecord: '/serviceRecord/delete', // 删除服务记录
  detailServiceRecord: '/serviceRecord/detail', // 服务记录详情
  getServiceObjectList: '/serviceRecord/serviceRecordSumPage', // 获取服务对象列表
  addRevisit: '/cmn/serviceRecord/addRevisit', // 服务记录回访

  getChgCompanyList: '/chgCompany/page', // 适老化改造 获取施工单位列表
  addOrSaveChgCompany: '/chgCompany/save', // 适老化改造 修改或新增施工单位
  deleteChgCompany: '/chgCompany/delete', // 适老化改造 删除施工单位
  detailChgCompany: '/chgCompany/detail', // 适老化改造 施工单位详情

  getChgPersonList: '/chgPerson/page', // 适老化改造 获取人员信息列表
  addOrSaveChgPerson: '/chgPerson/save', // 适老化改造 修改或新增人员信息
  deleteChgPerson: '/chgPerson/delete', // 适老化改造 删除人员信息
  detailChgPerson: '/chgPerson/detail', // 适老化改造 人员信息详情

  getBedPersonList: '/bedPerson/page', // 家庭养老床位 获取人员信息列表
  addOrSaveBedPerson: '/bedPerson/save', // 家庭养老床位 修改或新增人员信息
  deleteBedPerson: '/bedPerson/delete', // 家庭养老床位 删除人员信息
  detailBedPerson: '/bedPerson/detail', // 家庭养老床位 人员信息详情

  getBedCompanyList: '/bedCompany/page', // 家庭养老床位 获取施工单位列表
  addOrSaveBedCompany: '/bedCompany/save', // 家庭养老床位 修改或新增施工单位
  deleteBedCompany: '/bedCompany/delete', // 家庭养老床位 删除施工单位
  detailBedCompany: '/bedCompany/detail', // 家庭养老床位 施工单位详情

  getMktCompanyList: '/mktCompany/page', // 养老早市 获取机构列表
  addOrSaveMktCompany: '/mktCompany/save', // 养老早市 修改或新增机构
  deleteMktCompany: '/mktCompany/delete', // 养老早市 删除机构
  detailMktCompany: '/mktCompany/detail', // 养老早市 机构详情

  getMktCoreSeriveList: '/mktCoreSerive/pageList', // 养老早市 获取核心服务列表
  addOrSaveMtkCoreSerive: '/mktCoreSerive/save', // 养老早市 新增或者修改核心服务
  deleteMtkCoreService: '/mktCoreSerive/delete', // 养老早市 删除核心服务
  detailMktCoreService: '/mktCoreSerive/query', // 养老早市 核心服务详情

  getConsServiceRecordList: '/cons-service-record/page', // 养老顾问 获取服务记录列表
  addOrSaveConsServiceRecord: '/cons-service-record/save', // 养老顾问 修改或新增服务记录
  deleteConsServiceRecord: '/cons-service-record/delete', // 养老顾问 删除服务记录
  detailConsServiceRecord: '/cons-service-record/detail', // 养老顾问 服务记录详情

  addPubHealthReport: '/pubHealthReport/add', // 增加健康管理报告
  deletePubHealthReport: '/pubHealthReport/deleteById', // 删除健康管理报告
  getPubHealthReportList: '/pubHealthReport/pageList', // 健康管理报告列表
  updatePubHealthReport: '/pubHealthReport/update', // 修改健康管理报告

  homeCenterQueryData: '/homedatacenter/queryData', // 居家养老数据中心
  orgDataCenterQueryData: '/orgdatacenter/queryData', // 机构养老数据中心
  nursingHomeQueryData: '/cmdatacenter/queryData', // 社区养老数据中心

  getUserPageList: '/user/pageList', // 系统用户列表
  addSystemUser: '/user/addUser', // 增加系统用户
  getRoleList: '/user/roleList', // 获取角色列表
  getSubSysList: '/user/subSysList', // 获取角色
  addSystemAppUser: '/user/addUserFromOld',
  addSystemWorkPersonUser: '/user/addUserFromWork',
  // getWorkPersonListServe2: '/pubWorkPersonInfo/pageList3',
  // getWorkPersonListServe2:'/workPerson/pageFromEnt', // 获取从业人员列表
  getWorkPersonListServe2: '/workPerson/pageFromCmAndEnt', // 获取从业人员列表

  changeUserStatus: '/user/changeStatus', // 启用禁用系统用户
  addUserRole: '/user/addUserRole', // 增加用户角色
  userChangePwd: '/user/changepwd', // 系统用户修改密码
  userChangeMyPwd: '/user/changeMypwd', // 修改我的密码
  editUerInfo: '/user/editUserInfo',

  deleteRole: '/user/deleteRole', // 删除角色

  queryElectLocks: '/pubOldPersonTimeBank/pageList', // 查询时间银行

  oldPersonSignList: '/pubOldPersonSign/list', // 老人签到列表
  oldPsersonSign: '/pubOldPersonSign/sign', // 老人开始/结束打卡

  // 从业人员签到
  workPersonSignList: '/pubWorkPerson/list', // 从业人员签到列表
  workPersonSign: '/pubWorkPerson/sign', // 从业人员开始/结束打卡

  // 机构养老
  getorgInfoList: '/orgInfo/page', // 机构管理列表
  addorgInfo: '/orgInfo/save', // 机构管理添加
  delorgInfo: '/orgInfo/delete', // 机构管理删除
  gitpersonnellinfo: '/orgPerson/page', // 人员信息
  addorgStaff: '/orgStaff/save', // 添加人员信息
  addorgPerson: '/orgPerson/save', // 添加老人信息
  orgInfoDetail: '/orgInfo/detail', // 机构详情
  orgStaffList: '/orgStaff/page', // 员工信息
  orgPersonList: '/orgPerson/page', // 入住老人列表
  delorgPerson: '/orgPerson/delete', // 删除老人信息
  getOrgBedInfo: '/bedInfo/pageList',
  saveOrgBedInfo: '/bedInfo/saveBed',
  detailOrgBedInfo: '/bedInfo/detail',
  deleteOrgBedInfo: '/bedInfo/delete',

  // 老年餐
  getmechanismList: '/mealOrg/public/page', // 机构管理列表
  addmealOrg: '/mealOrg/save', // 机构管理添加
  delmealOrg: '/mealOrg/delete', // 删除
  mealRecord: '/mealRecord/page', // 用餐记录
  mealOrgdetail: '/mealOrg/public/detail', // 机构详情
  mealOrgPageListInfo: '/card/transaction/queryRecordPage',
  mealConsumptionRecordPageList: '/mealConsumptionRecord/pageList', // 消费记录

  // 饭卡管理
  getMealCardList: '/card/mealCard/pageList', // 饭卡开卡列表
  addMealCard: '/card/mealCard/addNew', // 开卡
  changeMealNum: '/card/mealCard/parseCardNum',
  searchPerson: '/cmn/oldPerson/search', // 开卡--查找人员
  destoryCard: '/card/mealCard/destory', // 销卡
  addTransCard: '/card/transaction/addTrans', // 饭卡充值
  cardTransList: '/card/transaction/queryPage', // 饭卡流水列表
  deleteTrans: '/card/mealCard/delete', // 退卡后删除列表数据
  // 养老顾问
  // 人员管理
  consConsultantPage: '/cons-consultant/page',
  addAgedCounselor: '/cons-consultant/save',
  detailAgedCounselor: '/cons-consultant/detail',
  // 养老早市
  agedMarketPage: '/mktMarket/page',
  agedMarketSave: '/mktMarket/save',
  // 任务管理
  getTaskOverview: '/getTaskOverview', // 获取任务总览
  getEquipmentCategory: '/equipmentCategory', // 获取设备类别
  getDeviceActivity: '/deviceActivity', // 获取设备活跃度
  getEquipmentDistribution: '/equipmentDistribution', // 获取设备分布
  getTodo: '/todo', // 获取代办事项
  getMaintenanceStatistics: '/maintenanceStatistics', // 获取维保统计
  getJobStatistics: '/jobStatistics', // 近6个月队伍作业统计
  getTaskDetail: '/taskOverview/list', // 获取任务明细
  getTaskToCurve: '/taskToCurve',
  // 设备管理
  getLocation: '/getLocation', // 获取设备定位
  getOneLocation: '/getOneLocation', // 获取针对指定设备号的定位信息
  getEquipmentOverview: '/equipmentOverview/list', // 获取设备总览
  getEquipmentDetails: '/equipmentDetails/list', // 获取设备明细
  getEquipType: '/equipmentType/list', // 获取设备类型
  // 数据管理
  getDataHistorical: '/dataHistorical/list', // 获取历史数据
  getDataRealtimel: '/getRealData', // 获取实时曲线
  getDataTransportation: '/getHistoryData', // 获取运行曲线
  getParaUnit: '/getParaUnit', // 获取信道选项
  getDataAlarm: '/getAllEquipmentLimitValue/list', // 获取报警数据
  getDataStatisticsm: '/getDataStatistics', // 获取数据统计
  getOilStatisticsm: '/getOilConsumptionStatistics', // 获取数据统计(油耗统计)
  getAlarmParams: '/limitValues', // 报警数据的获取与更新
  // 设备设置
  getArea: '/area/list', // 区域设置
  getTeam: '/team/list', // 队伍设置
  getTeamByArea: '/getTeamByArea', // 根据区域选择队伍
  getCategory: '/category/list', // 类别设置
  // 系统设置
  getDepartment: '/department/list', // 部门管理
  getUser: '/user/list', // 用户信息
  getRole: '/role/list', // 角色管理
  addUserAllocate: '/userData', // 添加用户权限
  editPassword: '/userPassword', // 修改密码
  getDictTypeList: '/dictType/pageList', // 数据字典类型
  // 服务记录
  getServeRecordsList: '/homeServiceRecord/pageList',
  addServeRecords: '/homeServiceRecord/save',
  updateServeRecords: '/homeServiceRecord/update',
  delServeRecords: '/homeServiceRecord/deleteById',
  getServeMonthPageList: '/homeServiceRecord/monthPageList',
  detailServeRecords: '/homeServiceRecord/detail', // 查服务记录详情
  getAllPageList: '/pubWorkPersonInfo/allPageList', // 服务记录添加获取服务员工

  // 服务企业
  getServeOrgsList: '/pubCompanyInfo/pageList',
  getServeOrgsList1: '/pubCompanyInfo/all/ent-and-cm', // 服务记录新增/修改 选择服务公司
  addServeOrgs: '/pubCompanyInfo/add',
  updateOrgs: '/pubCompanyInfo/update',
  delServeOrgs: '/pubCompanyInfo/deleteById',
  detailServeOrgs: '/pubCompanyInfo/detail', // 详情

  // 居家养老-核心服务
  getHomeCoreSeriveList: '/homeCoreSerive/pageList',
  addHomeCoreSerive: '/homeCoreSerive/save',
  delHomeCoreSerive: '/homeCoreSerive/delete',

  getDictList: '/sysDict/page', // 数据字典列表
  addDictType: '/dictType/add', // 数据字典类型添加
  deleteDictType: '/dictType/deleteDictType', // 数据字典类型删除
  addDict: '/sysDict/add', // 添加数据字典
  deleteDict: '/sysDict/deleteDict', // 删除数据字典
  menuList: '/menu/page', // 菜单列表
  logList: '/sysLog/pageList', // 访问日志
  uploadFileList: '/file/pageList', // 上传文件查询

  globalEvn: '/globalEnv/pageList', // 抬头管理
  saveGlobalEvn: '/globalEnv/saveInfo', // 新增抬头管理

  getSystemList: '/user/getSystemList', // 首页菜单展示
  // 呼叫中心
  getCallRecordsList: '/webcall/callList/pageList', // 获取通话记录列表
  getMissCallList: '/webcall/callLost/pageList', // 获取未接来电记录列表
  getServiceCallList: '/webcall/serviceList/pageList',
  getwebCallRecordFile: '/webcall/center/download',
  getCallTableList: '/webcall/center/report', // 获取呼叫报表列表

  getUserTypesServe: '/user/userType', // 用户类型

  // 日间照料
  cmOperationDailyList: '/cmOperationDaily/list', // 运营日报列表

  // 从业人员列表
  homeServiceRecordList: '/homeServiceRecord/list',

  // 获取默认头像
  getPhoto: '/file/download-user-photo',

  // 活动记录 查询从业人员（弹窗）
  // /pubWorkPersonInfo/pageList3'
  getWorkPersonPup: '/pubWorkPersonInfo/pageList3IsolationByTenant',
  // 活动记录
  addActRecord: '/action-record/add', // 添加活动记录
  actRecordList: '/action-record/list', // 活动记录列表
  actDetail: '/action-record/detail', // 活动详情
  deleteActRecord: '/action-record/delete', // 删除活动记录
  updataActRecord: '/action-record/update', // 修改活动记录

  // 老人 监控

  queryPersonMoniterList: '/pub-monitor/list', //  查询老人下所有监控
  addPersonMoniter: '/pub-monitor/add', // 新增老人监控
  delPersonMoniter: '/pub-monitor/delete', // 删除老人监控
  YSPersonAccessToken: '/pub-monitor/accessToken', // 获取老人监控token

  // 日照 监控

  queryMoniterList: '/cm-monitor/list', //  查询日照下所有监控
  addMoniter: '/cm-monitor/add', // 新增监控
  delMoniter: '/cm-monitor/delete', // 删除监控
  YSAccessToken: '/cm-monitor/accessToken', // 获取监控token

  // 日照资产
  queryAssetList: '/cm-asset-info/list', // 查询资产列表
  addAssset: '/cm-asset-info/add', // 增加资产
  updateAssset: '/cm-asset-info/update', // 修改资产信息
  delAsset: '/cm-asset-info/delete', // 删除资产

  queryClearningList: '/homeServiceRecord/list', // 打扫卫生列表

  serchRecord: 'homeServiceRecord/search-record', // 服务记录 老人姓名输入框搜索记录
  homeServiceRecordExport: '/homeServiceRecord/export', // 服务记录列表导出
  homeServiceRecordExport4: '/homeServiceRecord/list-4-export-word', // 服务记录列表导出
  homeServiceRecordExportImg: '/homeServiceRecord/list-4-export-img', // 导出服务记录图片
  homeServiceRecordOneExport: '/homeServiceRecord/export-wrod', // 服务记录单挑导出

  // 老年餐 数据管理 自动生成数据
  queryDataTask: '/card/transaction/curr-generate-task', // 查询
  addDataTask: '/card/transaction/generate-trans-record', // 创建
  closeDataTask: 'card/transaction/close-generate-task', // 关闭

  // 适老化改造
  queryolderReformList: '/pub-elderly-oriented-reform/list', // 查询列表
  elderReformDetai: '/pub-elderly-oriented-reform/detail', // 查看详情
  elderDataCenter: '/homedatacenter/elderly-reform', // 适老化数据中心
  visitingStatistics: '/homedatacenter/service-record/tftj', // 探访统计
  serviceStatistics: '/homedatacenter/service-record/fwtj', // 服务统计
  addElderReform: '/pub-elderly-oriented-reform/add', // 添加
  updateElderReform: '/pub-elderly-oriented-reform/update', // 修改
  deleteElderReform: '/pub-elderly-oriented-reform/delete', // 删除
  elderList: '/pub-elderly-oriented-reform/list-all', // 查询列表
  elderListExport: '/pub-elderly-oriented-reform/list-all-4-export', // 查询列表
  applyDetail: '/pub-elderly-oriented-reform/apply/detail', // 改造申请详情
  applyAdd: '/pub-elderly-oriented-reform/apply/add', // 改造申请添加
  applyUpdate: '/pub-elderly-oriented-reform/apply/update', // 改造申请详修改
  applyDelete: '/pub-elderly-oriented-reform/apply/delete', // 改造申请删除
  basicDetail: '/pub-elderly-oriented-reform/basic-info/detail', // 信息确认详情
  basicAdd: '/pub-elderly-oriented-reform/basic-info/add', // 信息确认添加
  basicUpdate: '/pub-elderly-oriented-reform/basic-info/update', // 信息确认修改
  basicDelete: '/pub-elderly-oriented-reform/basic-info/delete', // 信息确认删除
  personDetail: '/pub-elderly-oriented-reform/person-assess/detail', // 老人评估详情
  personDelete: '/pub-elderly-oriented-reform/person-assess/delete', // 老人评估删除
  requireDetail: '/pub-elderly-oriented-reform/require/detail', // 需求确认详情
  requireAdd: '/pub-elderly-oriented-reform/require/add', // 需求确认详情
  requireUpdate: '/pub-elderly-oriented-reform/require/update', // 需求确认详情
  requireDelete: '/pub-elderly-oriented-reform/require/delete', // 需求确认详情
  constructDetail: '/pub-elderly-oriented-reform/construct/detail', // 改造施工详情
  constructDelete: '/pub-elderly-oriented-reform/construct/delete', // 改造施工删除
  acceptDetail: '/pub-elderly-oriented-reform/accept/detail', // 需求验收详情
  acceptDelete: '/pub-elderly-oriented-reform/accept/delete', // 需求验收删除
  giveUpDetail: '/pub-elderly-oriented-reform/give-up/detail', // 放弃承诺书详情
  giveUpAdd: '/pub-elderly-oriented-reform/give-up/add', // 放弃承诺书添加
  giveUpUpdate: '/pub-elderly-oriented-reform/give-up/update', // 放弃承诺书修改
  giveUpDelete: '/pub-elderly-oriented-reform/give-up/delete', // 放弃承诺书删除
  projectCategoryList: '/pub-elderly-oriented-reform/project/list-all-category', // 项目类型分类
  getProjectList: '/pub-elderly-oriented-reform/project/list', // 项目类型
  projectAdd: '/pub-elderly-oriented-reform/project/add', // 项目类型添加
  projectUpdate: '/pub-elderly-oriented-reform/project/update', // 项目类型编辑
  projectDelete: '/pub-elderly-oriented-reform/project/delete', // 项目类型删除
  projectDetail: '/pub-elderly-oriented-reform/project/detail', // 项目类型详情
  productCategoryList: '/pub-elderly-oriented-reform/product/list-all-category', // 产品类型
  getProductList: '/pub-elderly-oriented-reform/product/list', // 产品
  productAdd: '/pub-elderly-oriented-reform/product/add', // 产品添加
  productUpdate: '/pub-elderly-oriented-reform/product/update', // 产品编辑
  productDelete: '/pub-elderly-oriented-reform/product/delete', // 产品删除
  productDetail: '/pub-elderly-oriented-reform/product/detail', // 产品详情
  elderlyOrientedDown: '/pub-elderly-oriented-reform/down-word', // 表格导出
  elderlyOrientedSummaryDetail: '/pub-elderly-oriented-reform/summary-detail', // 信息汇总详情
  elderlyOrientedSummaryDelete: '/pub-elderly-oriented-reform/summary-delete', // 信息汇总删除
  elderlyOrientedDownAll: '//pub-elderly-oriented-reform/down-all-word', // 表格导出

  elderlyAuditList: '/pub-elderly-reform-audit/list', // 审核列表
  elderlyAuditAudit: '/pub-elderly-reform-audit/audit', // 审核
  elderlyAuditAuditAll: '/pub-elderly-reform-audit/one-click-audit', // 一键审核
  elderlyAuditDelete: '/pub-elderly-reform-audit/delete', // 审核删除
  elderlyAuditDetail: '/pub-elderly-reform-audit/detail', // 审核详情

  // 人员评估
  assessList: '/pub-person-assess/list', // 人员评估查询列表
  assessListDetail: '/pub-person-assess/detail', // 人员评估详情
  addAssess: '/pub-person-assess/add', // 新增人员评估
  updateAssess: '/pub-person-assess/update', // 修改
  deleteAssess: '​/pub-person-assess​/delete', // 删除

  // 服务报告
  addreport: '/pub-service-report/add', // 新增
  deletereport: '​/pub-service-report​/delete', // 删除
  reportDetail: '/pub-service-report/detail', // 查详情
  reportList: '/pub-service-report/list', // 查列表
  updatereport: '/pub-service-report/update', // 修改

  // 消杀
  addcleanList: '/sum-center-service-record/add', // 新增消杀记录
  queryCleanList: '/sum-center-service-record/list', // 查询列表
  updateCleanList: '/sum-center-service-record/update', // 修改
  deleteClean: '/sum-center-service-record/delete', // 删除
  cleanDetail: '/sum-center-service-record/detail', // 查详情

  // 智慧养老床位管理平台
  getDataFlash: '/bed-data-express/base-info-statistics', // 数据快报
  getBedList: '/bed-alarm-record/bed-list', // 智慧养老床位 - 查列表
  getFlashList: '/bed-alarm-record/list', // 预警信息 - 查列表
  getFlashDetail: '/bed-alarm-record/detail', // 预警信息 - 详情
  clickProcessing: '/bed-alarm-record/handle-all', // 预警信息 - 一键处理
  getWeekReport: '/bed-alarm-record/bed-weekly', // 周报
  textToVol: '/vol/text-to-vol', // 语音处理
  exportExcelWeeks:'/bed-alarm-record/bed-weekly-excel', //下载周报

  // 关爱平台
  caringPlatformDevice: '/bed-data-express/base-info-statistics', // 基础数据统计
  caringPlatformOlderPersonList: '/ga-platform/person-list', // 关爱平台老人
  caringPlatformOlderPersonListExport: '/ga-platform/device-status', // 关爱平台导出
  caringPlatformHdInfo: '/ga-platform/device/hd-info', // 智能设备上报信息 - 活动传感器 (注：按天分页)
  caringPlatformSbInfo: '/ga-platform/device/sb-info', // 智能设备上报信息 - 定位手表
  caringPlatformSosInfo: '/ga-platform/device/sos-info', // 智能设备上报信息 - 紧急报警器
  caringPlatformYgInfo: '/ga-platform/device/yg-info', // 智能设备上报信息 - 烟感报警器 (注：按天分页)

  // 老人体检报告
  examReportAdd: '/pub-person-physical-exam-report/add', // 老人体检报告新增
  getExamReport: '/pub-person-physical-exam-report/detail-by-person-id', // 老人体检报告获取

  // 养老院
  nursingHomePublicPageList: '/nursingHome/public/pageList', // 列表
  nursingHomeAdd: '/nursingHome/add', // 新增
  nursingHomePublicDetail: '/nursingHome/public/detail', // 详情
  nursingHomeUpdate: '/nursingHome/update', // 修改

  // 新闻
  newsPublicPageList: '/news/public/pageList', // 列表
  newsAdd: '/news/add', // 新增
  newsPublicDetail: '/news/public/detail', // 详情
  newsUpdate: '/news/update', // 修改
  newsDelete: '/news/delete', // 删除

  // 积分商城-商品
  goodsPublicPageList: '/goods/public/pageList', // 列表
  goodsAdd: '/goods/add', // 新增
  goodsPublicDetail: '/goods/public/detail', // 详情
  goodsUpdate: '/goods/update', // 修改

  // 积分商城-兑换
  goodsExchangePageList: '/goodsExchange/pageList', // 列表
  goodsExchangeCheckout: '/goodsExchange/checkout', // 核销

  // 邻里圈-话题
  neighborhoodTopicsPublicPageList: '/neighborhoodTopics/public/pageList', // 列表
  neighborhoodTopicsAdd: '/neighborhoodTopics/add', // 新增
  neighborhoodTopicsUpdate: '/neighborhoodTopics/update', // 修改
  neighborhoodTopicsDelete: '/neighborhoodTopics/delete', // 删除

  // 邻里圈-邻里圈
  neighborhoodPublicPageList: '/neighborhood/public/pageList', // 列表
  neighborhoodAdd: '/neighborhood/add', // 新增
  neighborhoodPublicDetail: '/neighborhood/public/detail', // 详情
  neighborhoodUpdate: '/neighborhood/update', // 修改

  // 服务直通车-意见反馈
  feedbackPageList: '/feedback/pageList', // 列表
  feedbackUpdate: '/feedback/update', // 修改
  feedbackDelete: '/feedback/delete', // 删除

  // 志愿者管理-志愿者
  volunteerPageList: '/volunteer/pageList', // 列表
  
  gotoDecisionMakeMethod: '/volunteerTeam/submitCheckMethod', //  志愿者团队成立后审核通过


  // 志愿者管理-志愿者小组
  volunteerTeamPublicPageList: '/volunteerTeam/public/pageList', // 列表
  volunteerTeamPublicPageMembersList: '/volunteerTeam/public/pageMembersList', // 团队成员列表
  volunteerTeamerChange:'/volunteerTeam/volunteerTeamerChange' ,//团队队长更换

  // 任务管理-任务
  pubTaskPublicPageList: '/pubTask/public/pageList', // 列表
  pubTaskPublicPageClaimList: '/pubTask/public/pageClaimList', // 任务认领列表
  pubTaskAdd: '/pubTask/add', // 新增
  pubTaskPublicDetail: '/pubTask/public/detail', // 详情
  pubTaskUpdate: '/pubTask/update', // 修改

  // 党群管理-党组织
  partyOrganizationPublicPageList: '/partyOrganization/public/pageList', // 列表
  partyOrganizationAdd: '/partyOrganization/add', // 新增
  partyOrganizationUpdate: '/partyOrganization/update', // 修改

  // 党群管理-党员
  partyPageList: '/party/pageList', // 列表

  // 配置-小程序导航
  miniappMenuCategoryList: '/miniappMenu/categoryList', // 分类列表
  miniappMenuCategorySave: '/miniappMenu/categorySave', // 修改分类名称（修改条件：type=1）
  miniappMenuPublicMenuList: '/miniappMenu/public/menuList', // 导航列表
  miniappMenuMenuSave: '/miniappMenu/menuSave', // 修改导航

  // 文件上传
  fileUploadForAvatar: '/file/uploadForAvatar', // 文件上传并返回详细URL

  // 配置-小程序Banner
  miniappBannerPublicPageList: '/miniappBanner/public/pageList', // 列表
  miniappBannerAdd: '/miniappBanner/add', // 添加
  miniappBannerPublicDetail: '/miniappBanner/public/detail', // 详情
  miniappBannerUpdate: '/miniappBanner/update', // 修改
  miniappBannerDelete: '/miniappBanner/delete', // 删除

  // 审核
  auditAudit: '/audit/audit', // 审核

  // 账户-权限
  authList: '/role/resList', // 列表
  authAdd: '/role/resAdd', // 添加
  authUpdate: '/role/resUpdate', // 修改
  authDelete: '/role/resDelete', // 删除
  authGetUserAuth: '/role/getUserAuth', // 获取用户权限

  // 账户-角色
  roleList: '/role/list', // 列表
  roleAdd: '/role/roleAdd', // 添加
  roleUpdate: '/role/roleUpdate', // 修改
  roleDelete: '/role/roleDelete', // 删除
  roleGetAuth: '/role/getAuth', // 获取权限
  roleSetAuth: '/role/setAuth', // 设置权限

  // 用户
  pubuserPageList: '/pubuser/pageList', // 列表

  // 设备
  devicePageList: '/deviceInfo/pageList', // 列表
  deviceAdd: '/deviceInfo/add', // 添加
  deviceDetail: '/deviceInfo/detail', // 详情
  deviceUpdate: '/deviceInfo/update', // 修改
  deviceDelete: '/deviceInfo/delete', // 删除

  // 老年餐-菜品
  mealFoodPublicPageList: '/mealFood/public/pageList', // 列表
  mealFoodAdd: '/mealFood/add', // 添加
  mealFoodDetail: '/mealFood/detail', // 详情
  mealFoodUpdate: '/mealFood/update', // 修改
  mealFoodDelete: '/mealFood/delete' // 删除
}
export default API
