import API from '@/store/API/api'
import Axios from '@/store/API/axios'

export default {
  // 新闻列表
  newsPublicPageList({ state }, params) {
    return Axios.post(`${API.newsPublicPageList}`, params)
  },
  // 新增新闻
  newsAdd({ state }, params) {
    return Axios.post(`${API.newsAdd}`, params)
  },
  // 新闻详情
  newsPublicDetail({ state }, params) {
    return Axios.post(`${API.newsPublicDetail}/${params.id}`, params)
  },
  // 修改新闻
  newsUpdate({ state }, params) {
    return Axios.post(`${API.newsUpdate}`, params)
  }
}
